import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { Button } from '../common/components';
import { ButtonKinds } from '../common/enums';
import I18N, {setI18N} from '../common/i18n';
import LookupInput from '../inputs/LookupInput';

const b = block('ConfirmDialog');

export default
class SettingsDialog extends React.PureComponent {

  static propTypes = {
    closeModal: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      lang: localStorage.getItem('lang') || 'us',
      units: localStorage.getItem('units') || 'us',
      hour12: localStorage.getItem('hour12') || '0',
    };
  }

  _onOverlayClick = (e) => {
    if (e.target.className === b('Overlay'))
      this._onCancel();
  }

  _onSave = () => {
    localStorage.setItem('lang', this.state.lang);
    localStorage.setItem('units', this.state.units);
    localStorage.setItem('hour12', this.state.hour12);
    setI18N(this.state.lang, this.state.units, this.state.hour12);
    this.props.closeModal();
  }

  _onCancel = () => {
    this.props.closeModal();
  }

  _onChange = (field, value) => {
      console.log(field, value)
      this.setState({[field]: value});
  }

  render() {

    return (
      <div className={b()}>
        <div className={b('Overlay')} onClick={this._onOverlayClick}>
          <div className={b('WindowDynamicIndent')} />
          <div className={b('Window')}>
            <div className={b('Header')}></div>
                <div className={b('InputCaption')}>
                    <label htmlFor='lang'>{I18N.SETTINGS_LANGUAGE}</label>
                </div>
                    <LookupInput name='lang'
                        items={Object.entries(I18N.SETTINGS_LANGUAGES).map((v) => ({value: v[0], caption: v[1]}))}
                        onChangeValue={this._onChange}
                        value={this.state.lang}
                    />

                    <div className={b('InputCaption')}>
                        <label htmlFor='units'>{I18N.SETTINGS_MEASURES}</label>
                    </div>
                    <LookupInput name='units'
                        items={Object.entries(I18N.SETTINGS_MEASURE_UNITS).map((v) => ({value: v[0], caption: v[1]}))}
                        onChangeValue={this._onChange}
                        value={this.state.units}
                    />

                    <div className={b('InputCaption')}>
                        <label htmlFor='hour12'>{I18N.SETTINGS_TIMES}</label>
                    </div>
                    <LookupInput name='hour12'
                        items={Object.entries(I18N.SETTINGS_TIME).map((v) => ({value: v[0], caption: v[1]}))}
                        onChangeValue={this._onChange}
                        value={this.state.hour12}
                    />
            <div className={b('Buttons')}>
              <Button onClick={this._onSave}>OK</Button>
              <Button onClick={this._onCancel}>{I18N.SUBMIT_CANCEL}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
