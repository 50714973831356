// const location = window && window.location;
// const hostname = location.hostname;
// const protocol = location.protocol;
// const port = location.port;

// const backendHost = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

// const API_ROOT = `${backendHost}/api/v1`;
// const API_ROOT = `https://new.zig-zag.org/api/v1`;
// const API_ROOT = `https://api.test.new.zig-zag.org:443`;

export
let API_ROOT = ``;
let MAPI_ROOT = ``;

let env = 'development'
console.log(process.env.NODE_ENV)
if(env === 'development'){
	API_ROOT = 'https://api.dev.new.zig-zag.org:443';
	MAPI_ROOT = `http://mapi.dev.new.zig-zag.org`;
} else if(env === 'production'){
	// if(document.location.hostname.match('zig-zag.org')){ 
	// 	// check the work urls in production !!!
	// 	API_ROOT = `https://api.${document.location.hostname}:443`;
	// 	MAPI_ROOT = `http://mapi.${document.location.hostname}`;	
	// } else {
		API_ROOT = 'https://api.new.zig-zag.org:443';
		MAPI_ROOT = `http://mapi.new.zig-zag.org`;
	// }	
}

// if (document.location.hostname.match('zig-zag.org')) {
// 	console.log('match')
// 	console.log(document.location.hostname)
// 	API_ROOT = `https://api.${document.location.hostname}:443`;
// 	MAPI_ROOT = `http://mapi.${document.location.hostname}:443`;
// }

// else if (document.location.hostname === 'localhost') {
// 	console.log('match')
// 	console.log(document.location.hostname)
// 	// API_ROOT = `https://api.new.zig-zag.org:443`;
// 	API_ROOT = 'https://api.new.zig-zag.org:443';
// 	// API_ROOT = 'http://192.168.56.102:8080';
// 	MAPI_ROOT = `http://mapi.new.zig-zag.org`;
// }

let API_MAPBOX = `https://api.mapbox.com`

export const ENDPOINTS = {
	photo: `${MAPI_ROOT}/`,
	login: `${API_ROOT}/login`,
	signup: `${API_ROOT}/registration`,
	graphql: `${API_ROOT}/graphql`,
	files: `${API_ROOT}/files`,
	geocode: `${API_ROOT}/geocode`,
	mapboxGeocode: `${API_MAPBOX}/geocoding/v5/mapbox.places`,
	geocodeHint: `${API_ROOT}/geocode_hint`,
};
