import gql from 'graphql-tag';
import bindApollo from '../common/queries';
import { Sections } from '../../common/enums';


const courierScheduleFieldsFragment = `
  work_start,
  work_end,
  break_start,
  break_end,
  not_returning,
  single_run,
`;

const courierStatsFieldsFragment = `
  points_served,
  outoforder,
  total_time,
  total_distance,
  total_weight,
  total_cost,
`;

const courierCostsFieldsFragment = `
  cost_round,
  cost_hour,
  cost_km,
  cost_kgkm,
  cost_point,
`;

const courierDefaultFieldsFragment = `
  ${courierScheduleFieldsFragment}
  ${courierCostsFieldsFragment}
  id,
  name,
  phone,
  manifest_id,
  max_weight,
  max_volume,
  max_points,
  vehicle_type,
  vehicle_flags,
  longhaul,
  depot_id,
  garage_id,
  driver_id,
  current_point_id,
  current_weight,
  current_volume,
  current_time,
  current_distance,
  current_cost,
  through_depot,
  last_point_id,
  zones,
  extra_reqs,
`;

const courierListFragment = `
  id,
  name,
  ${courierScheduleFieldsFragment}
  ${courierCostsFieldsFragment}
  ${courierStatsFieldsFragment}
  driver {
    id,
    name,
    phone,
  },
  last_position,
  last_position_time,
  max_weight,
  max_volume,
  vehicle_type,
  zones,
  depot_id,
  garage_id,
`;

export
const queries = {
  SECTION: Sections.COURIERS,

  CREATE: gql`
    mutation CREATE_MANIFEST_COURIER($manifest_id: int!, $data: String!) {
      result(manifest_id: $manifest_id data: $data) {
        ${courierDefaultFieldsFragment}
      }
    }
  `,

  DELETE: gql`
    mutation DELETE_MANIFEST_COURIER($id: Int!) {
      result(id: $id)
    }
  `,

  DELETE_LIST: gql`
    mutation DELETE_MANIFEST_COURIERS($data: Array!) {
      result(data: $data)
    }
  `,

  LIST: gql`
    query MANIFEST_COURIERS ($limit: Int $offset: Int $manifest_id: Int $title_filter: String  $complete: Boolean) {
      result(limit: $limit offset: $offset manifest_id: $manifest_id title_filter: $title_filter complete: $complete)
      @connection(key: "couriers", filter: ["title_filter", "manifest_id", "complete"]) {
        ${courierListFragment}
      }
    }
  `,

  LIST_DETAILED: gql`
    query MANIFEST_COURIERS_DETAILED ($manifest_id: Int) {
      result(manifest_id: $manifest_id)
      @connection(key: "couriers", filter: ["manifest_id"]) {
        ${courierDefaultFieldsFragment}
        driver {
          id
          name
          phone
        }
      }
    }
  `,

  GET_ONE: gql`
    query MANIFEST_COURIER ($id: Int!) {
      result(id: $id) {
        ${courierDefaultFieldsFragment}
        ${courierStatsFieldsFragment}
      }
    }
  `,

  UPDATE: gql`
    mutation UPDATE_MANIFEST_COURIER($manifest_id: int!, $data: String!) {
      result(manifest_id: $manifest_id data: $data) {
        ${courierDefaultFieldsFragment}
      }
    }
  `,

  CLONE: gql`
    mutation COPY_COURIERS($from_id: Int $to_id: Int $with_linked_points: Bool $with_linked_zones: Bool) {
      result(from_id: $from_id to_id: $to_id with_linked_points: $with_linked_points with_linked_zones: $with_linked_zones) {
        null
      }
    }
  `,

  IMPORT_COURIERS: gql`
  mutation CREATE_MANIFEST_COURIERS($manifest_id: Int!, $data: Array!) {
    result(manifest_id: $manifest_id, data: $data) {
      ${courierDefaultFieldsFragment}
    }
  }
  `,
};

export
const CRUD = bindApollo(queries);
