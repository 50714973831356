import React from 'react';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
// import { PropTypes } from 'prop-types';

import icons from '../../common/icons';
import { reducer as authReducer } from '../../auth/actions';
import I18N from '../../common/i18n';
import { Button } from '../../common/components';

import {decodeDateRU} from '../../../base/utils';

const b = block('SubscriptionNotification');

let showNotification = true;

class SubscriptionNotification extends React.PureComponent {
  static propTypes = {
    // subscriptionExpires: PropTypes.date,
  }

  constructor(props) {
    super(props);
    this.state = {showNotification: showNotification};
  }

  onClose = () => {
      showNotification = false;
      this.setState({showNotification: false});
  }

  render() {
    const dt = this.props.subscriptionExpires && (this.props.subscriptionExpires.getTime() - (new Date()).getTime())/(1000 * 3600 * 24);
    return this.state.showNotification && (dt != null) && (dt <= 21)
      ? <div className={b({'Critical': dt <= 7})}>
           {`${I18N.NOTIFY_SUBSCRIPTION_EXPIRES} ${I18N.formatDate(this.props.subscriptionExpires)}.`}
          <Button className={b('CloseButton')} icon={icons.COMMON_CLOSE} onClick={this.onClose} />
        </div>
      : null;
  }
}

export default
connect(
  (state) => ({
    subscriptionExpires: (state[authReducer.name].user
        && state[authReducer.name].user.subscription_expires
        && new Date(Date.parse(state[authReducer.name].user.subscription_expires)))
  }),
)(SubscriptionNotification);
