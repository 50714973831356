import React from 'react';
import block from 'bem-cn-lite';
import {
  Map,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
import { connect } from 'react-redux';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import PrintControl from 'react-leaflet-easyprint';

import { client } from '../../index';
import { reducer as mapReducer } from './actions';
import { reducer as manifestReducer } from '../manifest/actions';
import { reducer as catalogReducer } from '../catalog/actions';
import { actions as sectionActions } from '../sections/common/actions';
import { MapElements } from '../common/enums';

import LeafletMapNoPoints from './LeafletMapNoPoints';
import LeafletMapPointsLayer from './LeafletMapPointsLayer';
import LeafletMapRoutesLayer from './LeafletMapRoutesLayer';
import LeafletMapZonesLayer from './LeafletMapZonesLayer';
import LeafletMapDrawingLayer from './LeafletMapDrawingLayer';
import LeafletMapTrackingLayer from './LeafletMapTrackingLayer';
import { loadWaybillsAsync, loadPointsCoordsAsync } from '../manifest/utils';

const b = block('LeafletMap');

const coordsSpan = (points) => {
  const min = [360, 360];
  const max = [-360, -360];

  for (const p of points) {
    if (!p.coords)
      continue;
    min[0] = Math.min(min[0], p.coords[0]);
    min[1] = Math.min(min[1], p.coords[1]);
    max[0] = Math.max(max[0], p.coords[0]);
    max[1] = Math.max(max[1], p.coords[1]);
  }

  return {min: min, max: max};
};

const getAppropriateZoom = (maxDiff) => {
  const empericalFactor = 1000;

  let zoom = 15;
  while (zoom > 0) {
    zoom--;
    if (empericalFactor / maxDiff > Math.pow(2, zoom))
      return zoom;
  }
  return 10;
};

let markersVersion = 0;

class LeafletMap extends React.Component {
  static propTypes = {
    fieldName: PropTypes.string,
    sourceRecucer: PropTypes.object,
    mapState: PropTypes.object,
    manifestId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      mapZoom: null,
      mapCenter: null,
      mapPoints: {},
      waybills: {},
      waybillInfo: {},
      pointsReady: false,
      waybillsReady: false
    };
  }

  componentDidMount() {
    this.pointCoordsUpdateEvent = sectionActions.pointCoordsUpdated.subscribe(
      (s, action) => {
        markersVersion = (markersVersion + 1) % 1000;
        if (action.source !== 'map') {
          this.setState((state) => {
            const newState = { ...state };
            if (newState.mapPoints[action.id])
              newState.mapPoints[action.id].coords = action.coords;
            return newState;
          });
        }
      });
    this.pointUpdateEvent = sectionActions.pointUpdated.subscribe(
      (s, action) => {
        if (action.source !== 'map') {
          markersVersion = (markersVersion + 1) % 1000;
          this.setState((state) => {
            const newState = { ...state };
            newState.mapPoints[action.id] = {
              ...newState.mapPoints[action.id],
              garage: action.point.garage,
              depot: action.point.depot,
              pickupPoint: action.point.pickup_point,
              pointCourierId: action.point.courier_id,
            };
            return newState;
          });
        }
      });
    this.pointCreateEvent = sectionActions.pointCreated.subscribe(
      (s, action) => {
        markersVersion = (markersVersion + 1) % 1000;
        if (action.source !== 'map') {
          this.setState((state) => {
            const newState = { ...state };
            newState.mapPoints[action.id] = {
              id: action.id,
              coords: action.point.lat_lon,
              garage: action.point.garage,
              depot: action.point.depot,
              pickupPoint: action.point.pickup_point,
              pointCourierId: action.point.courier_id,
            };
            return newState;
          }, this._setMapParams);
        }
      });
    this.pointDeleteEvent = sectionActions.pointsDeleted.subscribe(
      (s, action) => {
        markersVersion = (markersVersion + 1) % 1000;
        if (action.source !== 'map') {
          this.setState((state) => {
            const newState = { ...state };
            action.ids.map((id) => delete newState.mapPoints[id]);
            return newState;
          }, this._setMapParams);
        }
      });
    this.massPointsUpdatedEvent = sectionActions.massPointsUpdated.subscribe((s, action) => {
      markersVersion = (markersVersion + 1) % 1000;
      if (action.source !== 'map')
        this._loadPoints();
    });

    this.unsubscribeResetStoreEvent = client.onResetStore(() => this.setState({
      pointsReady: false,
      waybillsReady: false,
      mapPoints: {},
      waybills: {},
      waybillInfo: {},
    }, this._loadNewData));

    this._loadNewData();
  }

  componentWillUnmount() {
    this.pointCoordsUpdateEvent.unsubscribe();
    this.pointUpdateEvent.unsubscribe();
    this.pointCreateEvent.unsubscribe();
    this.pointDeleteEvent.unsubscribe();
    this.massPointsUpdatedEvent.unsubscribe();
    this.unsubscribeResetStoreEvent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.manifestId !== prevProps.manifestId)
      client.resetStore();
  }

  _setMapParams = () => {
    const realPoints = Object.values(this.state.mapPoints).filter((p) => p.id > 0);
    if (realPoints.length) {
      const sp = coordsSpan(realPoints);
      const mapCenter = [(sp.min[0] + sp.max[0]) / 2, (sp.min[1] + sp.max[1]) / 2];
      const mapZoom = getAppropriateZoom(Math.max(sp.max[0] - sp.min[0], sp.max[1] - sp.min[1]));
      this.setState({
        mapCenter: mapCenter,
        mapZoom: mapZoom,
      });
    }
    else {
      this.setState({
        mapCenter: null,
        mapZoom: null,
      });
    }
  }

  _loadNewData = async () => {
    await this._loadPoints(this._setMapParams);
    this._loadWaybills();
  }

  _loadPoints = async (cb) => {
    const pointCoords = await loadPointsCoordsAsync(this.props.manifestId);

    if (pointCoords.length) {
      const mapPoints = {};
      for (const p of pointCoords || [])
        mapPoints[p.id] = {
          id: p.id,
          coords: p.lat_lon,
          garage: p.garage,
          depot: p.depot,
          pickupPoint: p.pickup_point,
          pointCourierId: p.courier_id,
        };

      this.setState((state) => {
        if (state.mapPoints[-1])
          mapPoints[-1] = state.mapPoints[-1];
        // Loading all points may finish after opening the point form for creating.
        // In this case we want to preserve the new point (with id -1) coordinates.

        return {...state,
          mapPoints: mapPoints,
          pointsReady: true
        };
      }, () => cb ? cb() : this.forceUpdate());
    }
    else {
      this.setState({ pointsReady: true });
    }
  }

  _loadWaybills = async () => {
    const waybillsRaw = await loadWaybillsAsync(this.props.manifestId);
    const waybillsFiltered = {};
    const waybillInfo = {};

    for (const wp of waybillsRaw) {
      const wb = waybillsFiltered[wp.courier_id] || [];
      // let order = 0;
      // if (wb.length > 0) {
      //   if (wb[wb.length-1].pointId === wp.point_id)
      //     order = wb[wb.length-1].order;
      //   else
      //     order = wb[wb.length-1].order+1;
      // }
      let order = Number(wp.points_served);
      wb.push({
        pointId: wp.point_id,
        waybillCourierId: wp.courier_id,
        order: order,
        routeCodes: (wp.route_code || '').split(';').filter((c) => c),
        errorCodes: (wp.error_code || '').split(';').filter((c) => c),
        path: wp.geometry
      });

      waybillsFiltered[wp.courier_id] = wb;
      waybillInfo[wp.point_id] = {
        waybillCourierId: wp.courier_id,
        order: order,
        routeCodes: (wp.route_code || '').split(';').filter((c) => c),
        errorCodes: (wp.error_code || '').split(';').filter((c) => c),
        status: wp.status,
      };
    }
    for (const w of _.values(waybillsFiltered))
      w.sort((p1, p2) => p1.order - p2.order);

    this.setState((state) => ({...state,
      waybills: waybillsFiltered,
      waybillInfo: waybillInfo,
      waybillsReady: true}));
  }

  _onZoomChange = (e) => {
    this.setState({
      mapZoom: e.target._zoom
    });
  }

  _renderMap = () => <Map
    center={this.state.mapCenter}
    zoom={this.state.mapZoom}
    onzoomend={this._onZoomChange}
    zoomControl={false}
    maxZoom={18}
  >
    <TileLayer
      key={this.props[this.props.sourceRecucer.name][MapElements.MAP]}
      className={b('Map', {'Transparent': !this.props[this.props.sourceRecucer.name][MapElements.MAP]})}
      attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      useCache={true}
      cacheMaxAge={14 * 24 * 60 * 60 * 1000} // 2 weeks in milliseconds
    />
    <LeafletMapZonesLayer
      key={`${this.props.manifestId}_zones`}
      manifestId={this.props.manifestId}
      showZones={this.props[this.props.sourceRecucer.name][MapElements.ZONES]}
    />
    <LeafletMapRoutesLayer
      key={`${this.props.manifestId}_routes`}
      manifestId={this.props.manifestId}
      mapPoints={this.state.mapPoints}
      waybills={this.state.waybills}
      selectedCourierId={this.props.mapState.selectedCourierId}
      showStraightRoutes={this.props[this.props.sourceRecucer.name][MapElements.LINES]}
      showRoutes={this.props[this.props.sourceRecucer.name][MapElements.PATH]}
    />
    <LeafletMapPointsLayer
      key={`${this.props.manifestId}_points_${markersVersion}`}
      selectedPoints={this.props.mapState.selectedPoints}
      selectedCourierId={this.props.mapState.selectedCourierId}
      editingPointId={this.props.mapState.editingPointId}
      manifestId={this.props.manifestId}
      mapPoints={this.state.mapPoints}
      waybillInfo={this.state.waybillInfo}
      operation={this.props.mapState.operation}
      manifestLoaded={this.state.pointsReady && this.state.waybillsReady}
      showClusters={this.props[this.props.sourceRecucer.name][MapElements.CLUSTERS]}
      showRegularPoints={this.props[this.props.sourceRecucer.name][MapElements.POINTS]}
      showAssignedPoints={this.props[this.props.sourceRecucer.name][MapElements.POINTS_ASSIGNED]}
    />
    <LeafletMapTrackingLayer
      key={`${this.props.manifestId}_tracks`}
      manifestId={this.props.manifestId}
      selectedCourierId={this.props.mapState.selectedCourierId}
      showTracks={this.props[this.props.sourceRecucer.name][MapElements.TRACKS]}
    />
    <LeafletMapDrawingLayer
      manifestId={this.props.manifestId}
      mapPoints={this.state.mapPoints}
      mapZoom={this.state.mapZoom}
      waybills={this.state.waybills}
      key={`${this.props.manifestId}_draw`}
      onChange={this.drawnCoordsChanged}
    />
    <ZoomControl position="bottomright" />
    <PrintControl
      position="bottomright"
      sizeModes={['Current', 'A4Portrait', 'A4Landscape']}
      hideControlContainer={false}
    />
  </Map>

  render() {
    return (
      <div className={b()}>
        {this.props.manifestId && this.state.pointsReady && _.isEmpty(this.state.mapPoints)
          ? <LeafletMapNoPoints manifestId={this.props.manifestId}/>
          : this._renderMap()}
      </div>
    );
  }
}

export default
connect(
  (state) => ({
    [manifestReducer.name]: state[manifestReducer.name],
    [catalogReducer.name]: state[catalogReducer.name],
    mapState: state[mapReducer.name],
  })
)(LeafletMap);
