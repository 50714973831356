import { default as UdmurtVtorProdActions } from './_clients/udmurt_vtor_product/custom_actions';
import { default as TboBlesk } from './_clients/tbo_blesk/custom_actions';
import { default as EcoService } from './_clients/ecoservice/custom_actions';
import { default as BassV } from './_clients/bassv/custom_actions';
import { default as Common } from './common';

const CustomActions = {
  udmurtVtorProd: UdmurtVtorProdActions,
  tboBlesk: TboBlesk,
  ecoservice: EcoService,
  bassv: BassV,
  common: Common
};

export default CustomActions;
