import React from 'react';
import htmlParser from 'html-react-parser';


import block from 'bem-cn-lite';
import { Link } from 'react-router-dom';

import {
  Icon,
} from '../../common/components';

const b = block('LayoutMainMenuItem');

const LayoutMainMenuItem = ({
  link,
  onClick,
  title,
  hint,
  disabled,
  itemIcon
}) => (
  <div className={b()}>
    {!disabled && ( ((/^http|skype|tel|mailto/u).test(link) || onClick)
      ? <a className={b('Link')} onClick={onClick} href={link} target={'_blank'}/>
      : <Link className={b('Link')} to={!disabled && link}/>)}
    <div className={b('Body', { 'Disabled': disabled })}>
      <div className={b('Icon')}>
        <Icon svgName={itemIcon}/>
      </div>
      <div className={b('Text')}>{title}</div>
    </div>
    <div className={b('HintWrapper')}>
    <div className={b('Hint')}>{htmlParser(hint)}</div></div>
  </div>
);

export default LayoutMainMenuItem;
