import React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import ItemForm from '../common/ItemForm';
import I18N from '../../common/i18n';
import { queries as courierQueries } from '../couriers/queries';
import { queries as pointQueries } from './queries';
import {
  CoordsInput,
  AddressInput,
  CheckboxInput,
  TextInput,
  TimeInput,
  NumberInput,
  HiddenInput,
  DBLookupInput,
  LookupInput,
  ComplexInput,
  SelectFlagsInput,
  // WeekdaySelect
} from '../../inputs';
import { actions as mapActions } from '../../map/actions';
import { actions as sectionActions} from '../common/actions';
import { 
  store, 
  routeTo 
} from '../../..';
import { correctBreakTime, getSectionLinkBody } from '../../../base/utils';
import { reducer as authReducer } from '../../auth/actions';
import { VALIDATE } from '../../inputs/validate';
import { Sections, ApolloFetchPolicy } from '../../common/enums';
import { loadCouriersAsync } from '../../manifest/utils';

export
const importfields = [
  {
    inputClass: TextInput,
    name: 'name',
    caption: I18N.POINTS_NAME,
    validate: VALIDATE.notEmpty,
  },
  {
    inputClass: TextInput,
    name: 'address',
    caption: I18N.POINTS_ADDRESS,
    validate: VALIDATE.notEmpty,
  },
  {
    inputClass: TextInput,
    name: 'lat',
    caption: I18N.POINTS_COORDS_LAT,
    validate: VALIDATE.numberOrNull,
  },
  {
    inputClass: TextInput,
    name: 'lon',
    caption: I18N.POINTS_COORDS_LON,
    validate: VALIDATE.numberOrNull,
  },
  {
    inputClass: TimeInput,
    name: 'work_start',
    caption: I18N.SCHEDULE_WORK_START,
    validate: VALIDATE.timeOrNull
  },
  {
    inputClass: TimeInput,
    name: 'work_end',
    caption: I18N.SCHEDULE_WORK_END,
    validate: VALIDATE.timeOrNull
  },
  {
    inputClass: TimeInput,
    name: 'break_start',
    caption: I18N.SCHEDULE_BREAK_START,
    validate: VALIDATE.timeOrNull
  },
  {
    inputClass: TimeInput,
    name: 'break_end',
    caption: I18N.SCHEDULE_BREAK_END,
    validate: VALIDATE.timeOrNull
  },
  {
    inputClass: NumberInput,
    name: 'weight',
    caption: I18N.POINTS_WEIGHT,
    validate: VALIDATE.numberOrNull,
  },
  {
    inputClass: NumberInput,
    name: 'volume',
    caption: I18N.POINTS_VOLUME,
    validate: VALIDATE.numberOrNull,
  },
  {
    inputClass: NumberInput,
    name: 'backhaul_weight',
    caption: I18N.POINTS_BACKHAUL_WEIGHT,
    validate: VALIDATE.numberOrNull,
  },
  {
    inputClass: NumberInput,
    name: 'backhaul_volume',
    caption: I18N.POINTS_BACKHAUL_VOLUME,
    validate: VALIDATE.numberOrNull,
  },
  {
    inputClass: TimeInput,
    name: 'shipping_time',
    caption: I18N.POINTS_SHIPPING_TIME,
    validate: VALIDATE.timeOrNull
  },
  {
    inputClass: NumberInput,
    name: 'priority',
    caption: I18N.POINTS_PRIORITY,
  },
  {
    inputClass: NumberInput,
    name: 'summa',
    caption: I18N.POINTS_SUM,
  },
  {
    inputClass: TextInput,
    name: 'comment',
    caption: I18N.POINTS_COMMENT,
  },
  {
    inputClass: CheckboxInput,
    name: 'garage',
    caption: I18N.POINTS_GARAGE,
  },
  {
    inputClass: CheckboxInput,
    name: 'depot',
    caption: I18N.POINTS_DEPOT,
  },
  {
    inputClass: TextInput,
    name: 'contact_person',
    caption: I18N.POINTS_CONTACT_PERSON,
  },
  {
    inputClass: TextInput,
    name: 'phone',
    caption: I18N.POINTS_PHONE,
  },
  {
    inputClass: NumberInput,
    name: 'courier_id',
    caption: I18N.POINTS_COURIER_ID,
    validate: VALIDATE.numberOrNull,
  },
  {
    inputClass: CheckboxInput,
    name: 'mandatory',
    caption: I18N.POINTS_MANDATOTY,
  },
  {
    inputClass: LookupInput,
    name: 'curbside',
    hasNull: true,
    items: [
      {
        value: 1,
        caption: I18N.POINTS_CURBSIDE_RIGHT
      },
      {
        value: 2,
        caption: I18N.POINTS_CURBSIDE_LEFT
      }
    ],
    caption: I18N.POINTS_CURBSIDE,
    size: 2,
    help: I18N.POINTS_CURBSIDE_HELP
  },
  {
    inputClass: ComplexInput,
    name: 'extra_reqs',
    section: Sections.POINTS
  }
];

const _getDeliveryFields = (measure, useTracking) => [
  {
    inputClass: TextInput,
    name: 'name',
    validate: VALIDATE.notEmpty,
    caption: I18N.POINTS_NAME,
    help: I18N.POINTS_NAME_HINT
  },
  {
    inputClass: AddressInput,
    name: 'address',
    validate: VALIDATE.notEmpty,
    coordsName: 'lat_lon',
    caption: I18N.POINTS_ADDRESS,
    help: I18N.POINTS_ADDRESS_HELP,
  },
  {
    inputClass: CoordsInput,
    name: 'lat_lon',
    validate: VALIDATE.notEmpty,
    captionLat: I18N.POINTS_COORDS_LAT,
    captionLon: I18N.POINTS_COORDS_LON,
    help: I18N.POINTS_COORDS_HELP
  },
  {
    inputClass: CheckboxInput,
    name: 'pickup_point',
    caption: I18N.POINTS_PICKUP,
    help: I18N.POINTS_PICKUP_HELP,
    size: 2,
  },
  {
    inputClass: DBLookupInput,
    caption: I18N.POINTS_FROM,
    name: 'from_id',
    size: 2,
    queryParams: {
      query: pointQueries.LIST_SPECIAL,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        'manifest_id': null,
      }
    },
    filter: (item) => item.depot || item.pickup_point,
    valueField: 'id',
    captionField: 'name',
    help: I18N.POINTS_FROM_HELP,
  },
  {
    inputClass: TimeInput,
    name: 'work_start',
    caption: I18N.SCHEDULE_WORK_START,
    help: I18N.POINTS_DELIVERY_SCHEDULE_HELP
  },
  {
    inputClass: TimeInput,
    name: 'work_end',
    caption: I18N.SCHEDULE_WORK_END
  },
  {
    inputClass: TimeInput,
    name: 'break_start',
    caption: I18N.SCHEDULE_BREAK_START,
    help: I18N.POINTS_DELIVERY_BREAK_HELP
  },
  {
    inputClass: TimeInput,
    name: 'break_end',
    caption: I18N.SCHEDULE_BREAK_END
  },
  ...measure
    ? [{
      inputClass: HiddenInput,
      name: 'weight'
    },
    {
      inputClass: HiddenInput,
      name: 'volume'
    },
    {
      inputClass: NumberInput,
      name: 'mesure_value',
      caption: `${measure.name} (${I18N.POINTS_DIRECT})`,
      // validate: VALIDATE.notEmpty,
      size: 2,
      help: `${I18N.POINTS_CARGO_HELP}<br/><br/>
        ${measure.description}<br/><br/>
        ${I18N.MEASURES_WEIGHT_UNITS}: <b>${measure.weight_units}</b><br/>
        ${I18N.MEASURES_VOLUME_UNITS}: <b>${measure.volume_units}</b>`,
    }]
    : [{
      inputClass: NumberInput,
      name: 'weight',
      caption: I18N.POINTS_WEIGHT,
      // validate: VALIDATE.notEmpty,
      help: I18N.POINTS_CARGO_HELP
    },
    {
      inputClass: NumberInput,
      name: 'volume',
      caption: I18N.POINTS_VOLUME,
      // validate: VALIDATE.notEmpty,
      help: I18N.POINTS_CARGO_HELP
    }],
  ...measure
    ? [{
      inputClass: HiddenInput,
      name: 'backhaul_weight'
    },
    {
      inputClass: HiddenInput,
      name: 'backhaul_volume'
    },
    {
      inputClass: NumberInput,
      name: 'backhaul_mesure_value',
      caption: `${measure.name} (${I18N.POINTS_BACKHAUL})`,
      size: 2,
      help: `${I18N.POINTS_BACKHAUL_CARGO_HELP}<br/><br/>
        ${measure.description}<br/><br/>
        ${I18N.MEASURES_WEIGHT_UNITS}: <b>${measure.weight_units}</b><br/>
        ${I18N.MEASURES_VOLUME_UNITS}: <b>${measure.volume_units}</b>`,
    }]
    : [{
      inputClass: NumberInput,
      name: 'backhaul_weight',
      caption: I18N.POINTS_BACKHAUL_WEIGHT,
      help: I18N.POINTS_BACKHAUL_CARGO_HELP
    },
    {
      inputClass: NumberInput,
      name: 'backhaul_volume',
      caption: I18N.POINTS_BACKHAUL_VOLUME,
      help: I18N.POINTS_BACKHAUL_CARGO_HELP
    }],
  {
    inputClass: TimeInput,
    name: 'shipping_time',
    min: 0,
    caption: I18N.POINTS_SHIPPING_TIME,
    help: I18N.POINTS_SHIPPING_TIME_HELP
  },
  {
    inputClass: NumberInput,
    name: 'priority',
    min: 0,
    caption: I18N.POINTS_PRIORITY,
    help: I18N.POINTS_PRIORITY_HINT
  },
  {
    inputClass: NumberInput,
    name: 'summa',
    caption: I18N.POINTS_SUM,
    size: 2,
    help: I18N.POINTS_SUM_HINT,
  },
  // { 
  //   // Developent
  //   inputClass: WeekdaySelect, 
  //   name: 'weekdays',
  //   caption: I18N.POINTS_DAY,
  //   size: 1,
  //   help: I18N.POINTS_DAY_HINT,
  // },
  {
    inputClass: TextInput,
    name: 'comment',
    caption: I18N.POINTS_COMMENT,
    size: 4,
    help: I18N.POINTS_COMMENT_HINT
  },
  {
    inputClass: TextInput,
    name: 'contact_person',
    caption: I18N.POINTS_CONTACT_PERSON,
    size: 2,
    help: I18N.POINTS_CONTACT_PERSON_HELP
  },
  {
    inputClass: TextInput,
    name: 'phone',
    caption: I18N.POINTS_PHONE,
    size: 2,
    help: I18N.POINTS_CONTACT_HINT
  },
  {
    inputClass: DBLookupInput,
    name: 'courier_id',
    caption: I18N.POINTS_ASSIGNED_COURIER_ID,
    queryParams: {
      query: courierQueries.LIST,
      variables: {
        'manifest_id': null,
      }
    },
    size: 2,
    valueField: 'id',
    captionField: 'name',
    help: I18N.POINTS_COURIER_HELP,
  },
  ...(!useTracking ? [] :
    [{
      inputClass: TextInput,
      name: 'track_code',
      caption: I18N.POINTS_TRACK_LINK,
      toView: (v) => `https://tracking.zig-zag.org/${v}`,
      disabled: true,
    }]),
  {
    inputClass: LookupInput,
    name: 'curbside',
    hasNull: true,
    items: [
      {
        value: 1,
        caption: I18N.POINTS_CURBSIDE_RIGHT
      },
      {
        value: 2,
        caption: I18N.POINTS_CURBSIDE_LEFT
      }
    ],
    caption: I18N.POINTS_CURBSIDE,
    size: 2,
    help: I18N.POINTS_CURBSIDE_HELP
  },
  {
    inputClass: CheckboxInput,
    name: 'mandatory',
    caption: I18N.POINTS_MANDATOTY,
    min: 0,
    help: I18N.POINTS_MANDATOTY_HELP,
    size: 2,
  },
  {
    inputClass: SelectFlagsInput,
    caption: I18N.FLAGS,
    name: 'vehicle_flags',
    size: 2,
    help: I18N.FLAGS_HELP
  },
  {
    inputClass: ComplexInput,
    name: 'extra_reqs',
    section: Sections.POINTS
  }
];

const baseFields = [
  {
    inputClass: HiddenInput,
    name: 'weight'
  },
  {
    inputClass: HiddenInput,
    name: 'volume'
  },
  {
    inputClass: TextInput,
    name: 'name',
    validate: VALIDATE.notEmpty,
    caption: I18N.POINTS_NAME_BASE,
    help: I18N.POINTS_NAME_BASE_HINT
  },
  {
    inputClass: CheckboxInput,
    name: 'garage',
    caption: I18N.POINTS_GARAGE,
    size: 2,
    help: I18N.POINTS_GARAGE_DEPOT_HINT
  },
  {
    inputClass: CheckboxInput,
    name: 'depot',
    caption: I18N.POINTS_DEPOT,
    size: 2,
    help: I18N.POINTS_GARAGE_DEPOT_HINT
  },
  {
    inputClass: AddressInput,
    name: 'address',
    validate: VALIDATE.notEmpty,
    coordsName: 'lat_lon',
    caption: I18N.POINTS_ADDRESS,
    help: I18N.POINTS_ADDRESS_HELP
  },
  {
    inputClass: CoordsInput,
    name: 'lat_lon',
    validate: VALIDATE.notEmpty,
    captionLat: I18N.POINTS_COORDS_LAT,
    captionLon: I18N.POINTS_COORDS_LON,
    help: I18N.POINTS_COORDS_HELP
  },
  {
    inputClass: TimeInput,
    name: 'work_start',
    caption: I18N.SCHEDULE_WORK_START,
    help: I18N.POINTS_BASE_SCHEDULE_HELP
  },
  {
    inputClass: TimeInput,
    name: 'work_end',
    caption: I18N.SCHEDULE_WORK_END
  },
  {
    inputClass: TimeInput,
    name: 'break_start',
    caption: I18N.SCHEDULE_BREAK_START,
    help: I18N.POINTS_BASE_BREAK_HELP
  },
  {
    inputClass: TimeInput,
    name: 'break_end',
    caption: I18N.SCHEDULE_BREAK_END
  },
  {
    inputClass: TimeInput,
    name: 'shipping_time',
    min: 0,
    caption: I18N.POINTS_SHIPPING_BASE_TIME,
    size: 2,
    help: I18N.POINTS_SHIPPING_BASE_TIME_HELP
  },
  {
    inputClass: ComplexInput,
    name: 'extra_reqs',
    section: Sections.POINTS
  }
];

const assignedCouriersField = {
  inputClass: LookupInput,
  name: '_assigned_couriers',
  caption: I18N.POINTS_ASSIGNED_COURIER_LIST,
  hasUndefined: true,
  size: 2,
  items: [],
  help: I18N.POINTS_ASSIGNED_COURIER_LIST_HELP
};

class PointForm extends React.PureComponent {

  static propTypes = {
    item: PropTypes.object,
    manifestId: PropTypes.number,
    title: PropTypes.string.isRequired,
    isBase: PropTypes.bool,
    measure: PropTypes.object,
    useTracking: PropTypes.bool
  }

  constructor() {
    super();
    this.state = {
      assignedCouriers: [],
    };
  }

  componentDidMount() {
    document.getElementsByClassName('ManifestTab__Title')[0].innerHTML = this.props.title;

    if (this.props.item.id === -1)
      store.dispatch(sectionActions.pointCreated(-1, this.props.item));

    store.dispatch(mapActions.editPoint(this.props.item.id));
    this.pointUpdateEvent = sectionActions.pointCoordsUpdated.subscribe(
      (state, action) => {
        if (action.source !== 'form' && action.id === this.props.item.id)
          this.itemForm.setFieldValue('lat_lon', action.coords);
      });

    if (this.props.isBase && this.props.item.id > 0) {
      loadCouriersAsync(this.props.manifestId)
        .then((couriers) => {
          this.setState({
            assignedCouriers: couriers
              .filter((c) => c['garage_id'] === this.props.item.id)
              .map((c) => ({ caption: c.name, value: c.id }))
          });
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item.id !== this.props.item.id)
      store.dispatch(mapActions.editPoint(this.props.item.id));
  }

  componentWillUnmount() {
    this.pointUpdateEvent.unsubscribe();
    store.dispatch(mapActions.editPoint(null));
    if (this.props.item.id === -1)
      store.dispatch(sectionActions.pointsDeleted([-1]));
    else
      store.dispatch(sectionActions.pointCoordsUpdated(this.props.item.id, this.props.item.lat_lon, 'form'));
  }

  onNewStateReady = (oldState, newState) => {
    newState['from_id'] && (newState['from_id'].queryParams.variables['manifest_id'] = this.props.manifestId);
    newState['courier_id'] && (newState['courier_id'].queryParams.variables['manifest_id'] = this.props.manifestId);

    if (oldState['garage'] || oldState['depot']) {
      newState['depot'].value = !newState['garage'].value && !oldState['depot'].value ? true : newState['depot'].value;
      newState['garage'].value = !newState['depot'].value && !oldState['garage'].value ? true : newState['garage'].value;
    }

    if (newState['mesure_value'] || newState['backhaul_mesure_value']) {
      if (newState['mesure_value'].value === undefined) {
        newState['mesure_value'].value = Math.floor(newState['weight'].value / this.props.measure['weight_units']);
        newState['backhaul_mesure_value'].value = Math.floor(newState['backhaul_weight'].value / this.props.measure['weight_units']);
      }
      else {
        newState['weight'].value = newState['mesure_value'].value * this.props.measure['weight_units'];
        newState['volume'].value = newState['mesure_value'].value * this.props.measure['volume_units'];
        newState['backhaul_weight'].value = newState['backhaul_mesure_value'].value * this.props.measure['weight_units'];
        newState['backhaul_volume'].value = newState['backhaul_mesure_value'].value * this.props.measure['volume_units'];
      }
    }

    if (newState['_assigned_couriers']
      && oldState['_assigned_couriers']
      && !oldState['_assigned_couriers'].value
      && newState['_assigned_couriers'].value
    )
      routeTo(`${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}/${newState['_assigned_couriers'].value}`);

    correctBreakTime(newState);
    if (oldState.lat_lon && !_.isEqual(oldState.lat_lon.value, newState.lat_lon.value)) {
      store.dispatch(sectionActions.pointCoordsUpdated(this.props.item.id, newState.lat_lon.value, 'form'));
    }
  }

  _getFields = () => {
    let fields = [];

    if (this.props.isBase) {
      fields = [...baseFields];

      if (this.state.assignedCouriers.length) {
        assignedCouriersField.items = this.state.assignedCouriers;
        fields.splice(fields.length - 1, 0, assignedCouriersField);
      }
    }
    else
      fields = [..._getDeliveryFields(this.props.measure, this.props.useTracking)];
    return fields;
  }

  render() {
    return <ItemForm
      {...this.props}
      key={`${this.props.item.id} ${this.props.measure && this.props.measure.id} ${this.state.assignedCouriers}`}
      onRef={(ref) => { this.itemForm = ref; } }
      fields={this._getFields()}
      onNewStateReady={this.onNewStateReady} />;
  }
}

export default
connect(
  (state) => ({
    measure: state[authReducer.name].user && state[authReducer.name].user.measure,
    useTracking: state[authReducer.name].user && state[authReducer.name].user.use_tracking
  }),
)(PointForm);
