
import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';

import I18N from '../../common/i18n';
import DataBadge from '../common/DataBadge';
import { Sections } from '../../common/enums';
import { reducer as mapReducer, actions as mapActions } from '../../map/actions';
import { queries } from '../points/queries';
import ItemForm from '../common/ItemForm';
import TabTitle from '../common/TabTitle';
import * as inputs from '../../inputs';
import { queries as courierQueries } from '../couriers/queries';
import { timeToDecimalHours, correctBreakTime, getSectionLinkBody } from './../../../base/utils';
import { loadPointsCoordsAsync } from '../../manifest/utils';
import { actions as sectionActions } from 'modules/sections/common/actions';
import ConfirmButton from '../../common/components/ConfirmButton';
import { routeTo } from '../../..';
import FlexibleScroll from '../common/FlexibleScroll';
import { DBLookupInput } from '../../inputs';
import {reducer as updateStateReducer, actions as updateStateActions} from '../../common/updateStateReducer';

const b = block('PointsDetails');

const nullAsUndefined = (val) => (val || val === '0') ? Number(val) : undefined;
const timeNullAsUndefined = (val) => timeToDecimalHours(val) === null ? undefined : timeToDecimalHours(val);

const fields = [
  { inputClass: inputs.TimeInput, caption: I18N.SCHEDULE_WORK_START, name: 'work_start', fromView: timeNullAsUndefined },
  { inputClass: inputs.TimeInput, caption: I18N.SCHEDULE_WORK_END, name: 'work_end', fromView: timeNullAsUndefined },
  { inputClass: inputs.TimeInput, caption: I18N.SCHEDULE_BREAK_START, name: 'break_start', fromView: timeNullAsUndefined },
  { inputClass: inputs.TimeInput, caption: I18N.SCHEDULE_BREAK_END, name: 'break_end', fromView: timeNullAsUndefined },
  { inputClass: inputs.NumberInput, caption: I18N.POINTS_WEIGHT, name: 'weight', fromView: nullAsUndefined },
  { inputClass: inputs.NumberInput, caption: I18N.POINTS_BACKHAUL_WEIGHT, name: 'backhaul_weight', fromView: nullAsUndefined },
  { inputClass: inputs.NumberInput, caption: I18N.POINTS_VOLUME, name: 'volume', fromView: nullAsUndefined },
  { inputClass: inputs.NumberInput, caption: I18N.POINTS_BACKHAUL_VOLUME, name: 'backhaul_volume', fromView: nullAsUndefined },
  { inputClass: inputs.TimeInput, caption: I18N.POINTS_SHIPPING_TIME, name: 'shipping_time', fromView: timeNullAsUndefined },
  { inputClass: inputs.NumberInput, caption: I18N.POINTS_PRIORITY, name: 'priority', fromView: nullAsUndefined },
  {
    inputClass: inputs.DBLookupInput,
    name: 'courier_id',
    caption: I18N.POINTS_ASSIGNED_COURIER_ID,
    queryParams: {
      query: courierQueries.LIST,
      variables: {
        'manifest_id': null,
      }
    },
    valueField: 'id',
    size: 2,
    captionField: 'name',
    hasUndefined: true,
  },
  {
    inputClass: inputs.LookupInput,
    name: 'curbside',
    hasNull: true,
    items: [
      {
        value: 1,
        caption: I18N.POINTS_CURBSIDE_RIGHT
      },
      {
        value: 2,
        caption: I18N.POINTS_CURBSIDE_LEFT
      }
    ],
    caption: I18N.POINTS_CURBSIDE,
    size: 2,
    help: I18N.POINTS_CURBSIDE_HELP
  },
  {
    inputClass: inputs.SelectFlagsInput,
    caption: I18N.FLAGS,
    name: 'vehicle_flags',
    size: 2,
    help: I18N.FLAGS_HELP
  },
];

class PointsDetails extends React.PureComponent {
  static title = I18N.POINTS_DETAILS_TITLE

  static propTypes = {
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
    closeTab: PropTypes.func,
    stopSelectPoints: PropTypes.func,
    selectedPointsIds: PropTypes.arrayOf(PropTypes.number.isRequired),
    courierAssignments: PropTypes.arrayOf(PropTypes.number),
    massPointsUpdated: PropTypes.func,
    pointsDeleted: PropTypes.func,
  }

  constructor() {
    super();
    this.state = {
      count: 0,
      totalWeight: 0,
      totalVolume: 0,
      totalBackhaulWeight: 0,
      totalBackhaulVolume: 0,
      totalShippingTime: 0
    };
  }

  componentDidMount() {
    if (!this.props.orgId)
      routeTo(`${getSectionLinkBody(this.props.manifestId || -this.props.orgId)}`);
    else if (this.props.selectedPointsIds.length === 1)
      routeTo(`${getSectionLinkBody(this.props.manifestId || -this.props.orgId)}${Sections.POINTS}/${this.props.selectedPointsIds[0]}`);
    else
      this._setPointsData(this.props.selectedPointsIds);
  }

  componentDidUpdate() {
    this._setPointsData(this.props.selectedPointsIds);
  }

  componentWillUnmount() {
    this.props.stopSelectPoints();
  }

  _onNewStateReady = (oldState, newState) => {
    newState['courier_id'].queryParams.variables['manifest_id'] = this.props.manifestId || -this.props.orgId;
    correctBreakTime(newState);
  }

  _setPointsData = async (pointsIds) => {
    let newtotalWeight = 0;
    let newTotalVolume = 0;
    let newTotalBackhaulWeight = 0;
    let newTotalBackhaulVolume = 0;
    let newTotalShippingTime = 0;
    const points = await loadPointsCoordsAsync(this.props.manifestId || -this.props.orgId);

    if (points && points.length && pointsIds.length) {
      for (const point of points.filter((p) => pointsIds.includes(p.id))) {
        newtotalWeight += point.weight;
        newTotalVolume += point.volume;
        newTotalBackhaulWeight += point.backhaul_weight;
        newTotalBackhaulVolume += point.backhaul_volume;
        newTotalShippingTime += point.shipping_time;
      }
    }

    this.setState({
      count: pointsIds.length,
      totalWeight: newtotalWeight,
      totalVolume: newTotalVolume,
      totalBackhaulWeight: newTotalBackhaulWeight,
      totalBackhaulVolume: newTotalBackhaulVolume,
      totalShippingTime: newTotalShippingTime
    });
  }

  _getMassData = (data, pointsIds) => {
    const newData = [];
    for (const id of pointsIds)
      newData.push({ ...data, id: id });
    return newData;
  }

  _update = (update, data) => {
    if (Object.values(data).filter(v => v !== undefined).length === 0)
      return;

    const massData = this._getMassData(data, this.props.selectedPointsIds);

    const p = update({
      variables: {
        data: massData
      }
    });
    p.then((res) => {
      toast.success(`${I18N.POINTS_MASS_EDIT}. ${I18N.NOTIFY_SUCCESS}`);
      this.props.massPointsUpdated();
      routeTo(`${getSectionLinkBody(this.props.manifestId || -this.props.orgId)}${Sections.POINTS}`);
    });
    p.catch((res) => {
      toast.error(`${I18N.POINTS_MASS_EDIT}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  _delete = (del) => {
    del({
      variables: {
        data: this.props.selectedPointsIds
      }
    })
      .then((res) => {
        console.log('points deleted');
        toast.success(`${I18N.MISC_DELETE_MULTIPLE} ${I18N.NOTIFY_SUCCESS}`);
        routeTo(`${getSectionLinkBody(this.props.manifestId || -this.props.orgId)}`);
        this.props.pointsDeleted(res.data.result);
      })
      .catch((res) => {
        console.log('points delete error');
        toast.error(`${I18N.MISC_DELETE_MULTIPLE} ${I18N.NOTIFY_ERROR}`);
      });
  }

  _onCourierAssignmentChangeValue = (fieldName, fieldValue) => {
    this.setState({massAssignedCourierId: fieldValue});
    this.props.selectedPointsIds.forEach(id => this.props.assignCourier(id, fieldValue));
  }

  render() {
    return (
      <Mutation mutation={queries.DELETE_LIST}>
        {(del) => <Mutation mutation={queries.UPDATE_POINTS}>
          {(update) => <FlexibleScroll>
            <div className={b()}>
              <DataBadge caption={I18N.POINTS_COUNT} value={`${this.state.count}`}/>
              <DataBadge caption={I18N.POINTS_TOTAL_WEIGHT} value={`${this.state.totalWeight}`}/>
              <DataBadge caption={I18N.POINTS_TOTAL_VOLUME} value={`${this.state.totalVolume}`}/>
              <DataBadge caption={I18N.POINTS_TOTAL_BACKHAUL_WEIGHT} value={`${this.state.totalBackhaulWeight}`}/>
              <DataBadge caption={I18N.POINTS_TOTAL_BACKHAUL_VOLUME} value={`${this.state.totalBackhaulVolume}`}/>
              <DataBadge caption={I18N.POINTS_TOTAL_SHIPPING_TIME} value={`${I18N.formatDecimalHours(this.state.totalShippingTime)}`}/>
              <br/>
              <div className={b('Input')} style={{paddingRight: '5px'}}>
                <div className={b('InputCaption')}>
                  <label htmlFor={'courier_id'}>{I18N.POINTS_COURIER_ID}</label>
                </div>
                <DBLookupInput
                  name='courier_id'
                  queryParams={{
                    query: courierQueries.LIST,
                    variables: {
                      'manifest_id': this.props.manifestId,
                    }
                  }}
                  hasNull={true}
                  hasUndefined={true}
                  value={this.state.massAssignedCourierId}
                  valueField='id'
                  captionField='name'
                  onChangeValue={this._onCourierAssignmentChangeValue}
                />
              </div>
              <br/>
              <div className={b('Button')}>
                <ConfirmButton
                  disabled={this.state.count === 0}
                  confirmMessage={I18N.MISC_DELETE_MULTIPLE_CONFIRM}
                  onClick={() => this._delete(del)}>{I18N.POINTS_MASS_DELETE}</ConfirmButton>
              </div>
              <hr/>
              <TabTitle>{I18N.POINTS_MASS_EDIT}</TabTitle>
              <div className={b('Form')}>
                <ItemForm 
                  {...this.props}
                  submitText={I18N.SUBMIT_SAVE}
                  submitDisabled={this.state.count === 0}
                  fields={fields}
                  item={{}}
                  onNewStateReady={this._onNewStateReady}
                  submit={(data) => this._update(update, data)} />
              </div>
            </div>
          </FlexibleScroll>
          }
        </Mutation>}
      </Mutation>
    );
  }
}

export default
connect(
  (state) => ({
    selectedPointsIds: state[mapReducer.name].selectedPoints,
    courierAssignments: state[updateStateReducer.name].courierAssignments,
  }),
  (dispatch) => ({
    'stopSelectPoints': () => dispatch(mapActions.stopSelectPoints()),
    'massPointsUpdated': () => dispatch(sectionActions.massPointsUpdated()),
    'pointsDeleted': (ids) => dispatch(sectionActions.pointsDeleted(ids, 'form')),
    assignCourier: (pointId, courierId) => dispatch(updateStateActions.assignCourier(pointId, courierId)),
  })
)(PointsDetails);
