import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import * as inputs from './';
import ItemForm from '../sections/common/ItemForm';
import I18N from '../common/i18n';

const b = block('Input');

export default
class ComplexInput extends React.PureComponent {
  static fieldName = 'complex';

  static propTypes = {
    size: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.any,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    sourceActions: PropTypes.object,
    fieldsMetaData: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  _getInputComponent = (type) => {
    const Input = Object.values(inputs).find((input) => input.fieldName === type);
    if (!Input)
      throw new Error(`Unknown input type in custom form: ${type}`);
    return Input;
  }

  _getLookupItems = (objItems) => objItems ? Object.keys(objItems).map((k) => ({ value: k, caption: objItems[k] })) : [];

  _generateFields = (fields) => fields.map((f) => ({
    inputClass: this._getInputComponent(f.type),
    size: f.size,
    caption: f.caption,
    name: f.name,
    help: f.help,
    items: this._getLookupItems(f.items),
  }));

  _prepareFieldsData = (fieldsData) => {
    const data = {};
    Object.values(fieldsData)
      .filter((input) => input instanceof Object)
      .forEach((input) => { data[input.name] = input.value; } );
    return data;
  }

  onNewStateReady = (oldState, newState) => {
    const newData = this._prepareFieldsData(newState);
    this.props.onChangeValue && this.props.onChangeValue(this.props.name, newData);
  }

  render() {
    return (
      <React.Fragment>
        <h2>{I18N.FIELD_EXTRA_FIELDS}</h2>
        <hr/>
        <ItemForm
          {...this.props}
          className={b('ComplexInputForm')}
          fields={this._generateFields(this.props.fieldsMetaData)}
          item={this.props.value}
          onNewStateReady={this.onNewStateReady}
        />
      </React.Fragment>
    );
  }
}
