import { decodeTime, round } from "../../base/utils";





const distance = {
  'metric': {
    formatDistance(meters) {
      return `${round((isNaN(meters) ? 0 : meters) / 1000, 2)} ${this.DISTANCE_HIGH_ABBR_KM}`;
    },

    printDistance(dist) {
      return String(round(dist, 3));
    },
  },

  'us': {
    formatDistance(meters) {
      return `${round((isNaN(meters) ? 0 : meters) / 1000 / 1.609344, 2)} ${this.DISTANCE_HIGH_ABBR_MI}`;
    },

    printDistance(dist) {
      return String(round(dist / 1.609344, 2));
    },
  }
};

// const weight = {
//   'metric': {
//     formatWeight(numKilo) {
//       const kilo = round(isNaN(numKilo) ? 0 : numKilo, 2);
//       return kilo < 1000
//         ? `${kilo} ${this.WEIGHT_LOW_ABBR_KG}`
//         : `${round(kilo / 1000, 2)} ${this.WEIGHT_HIGH_ABBR_KG}`;
//     }
//   },
//   'us': {
//     formatWeight(numKilo) {
//       const lb = round(isNaN(numKilo) ? 0 : numKilo/0.454, 2);
//       return lb < 2000
//         ? `${lb} ${this.WEIGHT_LOW_ABBR_LB}`
//         : `${round(lb / 2000, 2)} ${this.WEIGHT_HIGH_ABBR_LB}`;
//     }
//   }
// };

const weight = {

}


const strings = {
  'ru': {
    DISTANCE_HIGH_ABBR_KM: 'км',
    DISTANCE_LOW_ABBR_KM: 'м',
    DISTANCE_HIGH_ABBR_MI: 'м',
    DISTANCE_LOW_ABBR_MI: 'ф',
    WEIGHT_HIGH_ABBR_KG: 'т',
    WEIGHT_LOW_ABBR_KG: 'кг',
    WEIGHT_HIGH_ABBR_LB: 'т',
    WEIGHT_LOW_ABBR_LB: 'ф',
    SETTINGS_LANGUAGE: 'Язык / Language',
    SETTINGS_MEASURES: 'Единицы измерения / Units',
    SETTINGS_LANGUAGES: {
      'ru': 'Русский / Russian',
      'us': 'Английский (США) / English (US)'
    },
    SETTINGS_MEASURE_UNITS: {
      'metric': 'Метрические / Metric',
      'us': 'Имперские (США) / Imperial (US)'
    },

    CATALOG_DOCUMENTS: 'Документы',
    POINTS_TOTAL_SHIPPING_TIME: 'Суммарное время обслуживания',
    POINTS_DAY: 'День',
    POINTS_DAY_HINT: 'День недели для маршрута',
    POINTS_MANDATOTY: 'Обязательно к посещению',
    POINTS_MANDATOTY_HELP: 'Обязательность к посещению - назначается тем точкам, которые должны быть посещены в обязательно. При этом маршрут строится оптимальным образом. Данная точка будет в произвольном порядковом месте в маршрутном листе. ',
    POINTS_CURBSIDE: 'Подъезд к точке',
    POINTS_CURBSIDE_HELP: 'Выбрать сторону машины с которой курьер будет подьезжать к точке',
    POINTS_CURBSIDE_RIGHT: 'Подъезжать справа',
    POINTS_CURBSIDE_LEFT: 'Подъезжать слева',
    ITEM_DOCUMENT_SIGNED: 'Подписать',
    ITEM_DOCUMENT_DOWNLOAD: 'Загрузить',
    DOCUMENT_INFO_TITLE: 'Список документов',
    DOCUMENT_SIGN_CONFIRM: 'При подписании документа вы безусловно акцептуете условия Договора-оферты',
    NOTIFY_UNSIGN_DOCUMENT: 'У Вас есть неподписанные документы, пожалуйста, подпишите Главное меню/Справочники/Документы',

    HOURS_ABBR: 'ч.',
    MINUTES_ABBR: 'м.',
    DAY_ABBR: 'д.',
    AREA_HECTARE: 'Гектар:',
    DATES_CLOSEST: 'Ближ. дни:',
    DATES_ALL: 'Даты:',
    MANIFEST_TITLE: 'Управление маршрутами',
    MANIFEST_LIST_TITLE: 'Список маршрутов',
    MANIFEST_ADD: 'Добавить маршрут',
    MANIFEST_SELECT: 'Выбрать маршрут',
    MANIFEST_STATUS_ENQUEUED: 'В очереди',
    MANIFEST_STATUS_SUCCESS: 'Маршрут расчитан',
    MANIFEST_STATUS_WARNING: 'Маршрут рассчитан с предупреждениями',
    MANIFEST_STATUS_ERROR: 'Ошибка в расчете',
    MANIFEST_STATUS_PREPARING: 'Рассчет матрицы',
    MANIFEST_STATUS_WAITING: 'Ожидание',
    MANIFEST_STATUS_PROCESSING: 'Рассчет маршрута',
    MANIFEST_STATUS_MISSED_POINTS: 'Есть ошибочные точки',
    MANIFEST_CREATE_TITLE: 'Создать новый маршрут',
    MANIFEST_CLONE_TITLE: 'Клонировать текущий маршрут',
    MANIFEST_EDIT_TITLE: 'Редактирование маршрута',
    MANIFEST_NAME: 'Название маршрута',
    MANIFEST_CURRENT: 'Текущий маршрут:',
    MANIFEST_NAME_HELP: 'Рабочее название маршрута для распознавания его в системе Zig-Zag',
    MANIFEST_DATE: 'Дата начала маршрута',
    MANIFEST_SETTINGS: 'Настройки',
    MANIFEST_SPECAIL_POINTS: 'Базовые адреса',
    MANIFEST_REGULAR_POINTS: 'Точки маршрута',
    MANIFEST_COURIERS: 'Курьеры',
    MANIFEST_ZONES: 'Районы',
    MANIFEST_DELETE_CONFIRM: 'Подтвердите удаление маршрута',
    MANIFEST_GET_WAYBILLS: 'Скачать маршрутные листы',
    MANIFEST_GET_WAYBILLS_HINT: 'Скачать маршрутные листы всех курьеров',
    MANIFEST_FIRST_MESSAGE: 'У нас нет ни одного маршрута, давайте создадим первый.',
    MANIFEST_NO_MAP_POINTS: 'Маршрут создан, но у нас нет точек с координатами, давайте добавим одну.',
    MANIFEST_EDIT_HINT: 'Изменение параметров текущего маршрута',
    MANIFEST_CLONE_HINT: 'Построение нового маршрута с возможностью полного или частичного копирования параметров текущего маршрута',
    MANIFEST_DELETE_HINT: 'Безвозвратное удаление текущего маршрута из системы',
    MANIFEST_MODE_MANUAL: 'Ручной',
    MANIFEST_MODE_MANUAL_HINT: 'Включен режим ручного редактирования. Нажмите кнопку справа для ручного построения маршрута.',
    MANIFEST_MODE_AUTO: 'Авто',
    MANIFEST_MODE_AUTO_HINT: 'Включен режим автоматического построения маршрута. Нажмите кнопку справа, чтобы рассчитать оптимальный маршрут',
    CATALOG_TITLE: 'Справочники',
    CATALOG_COURIERS: 'Справочник курьеров',
    CATALOG_USERS: 'Справочник пользователей',
    CATALOG_POINTS: 'Справочник адресов',
    CATALOG_ZONES: 'Справочник районов',
    CATALOG_MEASURES: 'Справочник единиц измерения',
    CATALOG_GROUPS: 'Справочник групп элементов',
    MAP_VIEW_MAP: 'Прозрачность карты. Возможность сделать базовую карту более прозрачной, для фокусировки внимания на слое с построенным маршрутом.',
    MAP_VIEW_POINTS: 'Отобразить точки. Отображение или скрытие всех точек маршрута на карте кроме базовых (склад / гараж), чтобы легче выделить их на карте. Номер точки указывает на очередность выполнения маршрута.',
    MAP_VIEW_ASSIGNED_POINTS: 'Отобразить закрепленные за курьерами точки. Удобно, когда точки распределяются вручную, лишние пропадают после назначения.',
    MAP_VIEW_CLUSTERS: 'Группировка точек. При изменении масштаба карты соседние точки группируются или разбиваются по одной. При объединении точек им присваивается номер, равный количеству точек в группе.',
    MAP_VIEW_LINES: 'Отобразить прямые линии. Соединение точек маршрута напрямую (пунктирными линиями) без учета построенного маршрута.',
    MAP_VIEW_PATH: 'Отобразить линии маршрута. Отображение линий построенного маршрута.',
    MAP_VIEW_ZONES: 'Отобразить районы. Отображение или скрытие ранее созданных районов.',
    MAP_VIEW_TRACKS: 'Отобразить треки. Отображение фактического местонахождения курьера во время выполнения маршрута. Возможно только при использовании курьером мобильного приложения.',
    MAP_CONTROLS_SELECT: 'Выделить точки на карте. Выделение точек областью позволяет просматривать и редактировать данные о них (режим работы, параметры груза, курьеры)',
    MAP_CONTROLS_UNSELECT: 'Закончить и отменить выделение',
    MANIFEST_TAB: 'Маршруты',
    MANIFEST_TAB_HINT: 'Список маршрутов',
    ORDERS_TAB: 'Заявки',
    ORDERS_TAB_HINT: 'Список заявок',
    HISTORY_TAB: 'История',
    HISTORY_TAB_HINT: 'История чего-то',
    REPORTS_TAB: 'Отчеты',
    REPORTS_TAB_HINT: 'Отчеты по выполненным маршрутам',
    DICTS_TAB: 'Справочники',
    DICTS_TAB_HINT: 'Базовая информация (о курьерах, автомобилях, адресах и районах) для создания маршрута. При построении маршрута поля можно автоматически заполнить данными из справочников',
    SETTINGS_TAB: 'Настройки',
    SETTINGS_TAB_HINT: 'Настройки организации',
    YOUTUBE_TAB: 'Обучение',
    YOUTUBE_TAB_HINT: 'Посмотрите обучающие ролики на нашем YouTube канале',
    SUPPORT_TAB: 'Помощь',
    SUPPORT_TAB_HINT: 'Появились вопросы или затруднения? Кликните, чтобы связаться с нашим специалистом через <b>support@zig-zag.org</b> или позвонить по тел. <b>8 (800) 250-41-43</b>',
    COURIERS_TAB: 'Доступные курьеры',
    COURIERS_TAB_HINT: 'Курьеры',
    COURIERS_NAME: 'Название курьера',
    COURIERS_NAME_HINT: 'Введите название курьера (например: имя курьера, госномер автомобиля, ник, псевдоним)',
    COURIERS_ADD_BUTTON: 'Добавить курьера',
    COURIERS_ADD_BUTTON_HINT: 'В данном разделе выбирается тип курьера (автокурьер/пеший курьер) и заносятся данные о режиме работы, расчете оплаты работы, параметрах груза',
    COURIERS_INFO_TITLE: 'Данные курьера',
    COURIERS_PHONE: 'Контактный телефон',
    COURIERS_INFO_WAYBILLS: 'Точки маршрутного листа',
    COURIERS_EDIT_TITLE: 'Редактирование курьера',
    COURIERS_CREATE_TITLE: 'Создание курьера',
    COURIERS_LIST_TITLE: 'Список курьеров',
    COURIERS_DELETE_CONFIRM: 'Подтвердите удаление курьера',
    COURIERS_POWER: 'Если выключить, то машина не попадает в расчет, но сохраняется история посещений точек маршрута. Например, если машина сломалась, надо пересчитать маршрут с учетом посещенных ею адресов.',
    COURIERS_MAX_VOLUME: 'Макс. объем груза',
    COURIERS_MAX_WEIGHT: 'Макс. вес груза',
    COURIERS_MAX_POINTS: 'Макс. число адресов',
    COURIERS_MAX_POINTS_HINT: 'Максимальное количество адресов за рабочий день',
    COURIERS_DRIVER: 'Назначить курьера',
    COURIERS_DRIVER_HINT: 'Назначая курьера Вы распределяете ему маршрут и отправляете маршрутный лист в мобильное приложение',
    COURIERS_CURRENT_POINT: 'Начало движения',
    COURIERS_CURRENT_POINT_HINT: `Точкой начала движения курьера по маршруту может быть:<br/><br/>
    <b>1. Гараж</b><br/>
    <b>2. Склад / гараж</b><br/>
    <b>3. Склад</b>`,
    COURIERS_CURRENT_WEIGHT: 'Начальный вес груза',
    COURIERS_CURRENT_VOLUME: 'Начальный объем груза',
    COURIERS_CURRENT_TIME: 'Время начала движения',
    COURIERS_CURRENT_DISTANCE: 'Начальное пройденное расстояние',
    COURIERS_CURRENT_COST: 'Начальная стоимость доставленного груза',
    COURIERS_NOT_RETURNING: 'Финиш в последней точке маршрута',
    COURIERS_NOT_RETURNING_HINT: 'Расчет и построение маршрута производится до последней точки маршрута. После окончания маршрута курьер не возвращается  в гараж и/или склад/гараж',
    COURIERS_SINGLE_RUN: 'Один выезд со склада',
    COURIERS_SINGLE_RUN_HINT: 'Выбор данного пункта подразумевает, что курьер в течении рабочего дня  будет выезжать со склада только один раз. Во время выполнения задания  не предусматривается возвращение курьера на склад для дозагрузки.',
    COURIERS_LONGHAUL: 'Многодневный маршрут',
    COURIERS_LONGHAUL_HINT: 'Маршрут протяженностью более 24 часов. Строится с учетом остановок на отдых и ночевку.',
    COURIERS_OUTOFORDER: 'Исключить из маршрута',
    COURIERS_OUTOFORDER_HINT: 'Если выбрано, то машина не попадает в расчет, но сохраняется история посещений точек маршрута. Пример использования: машина сломалась, надо пересчитать маршрут с учетом посещенных ею адресов.',
    COURIERS_COST_ROUND: 'Цена выезда на рейс',
    COURIERS_COST_HOUR: 'Цена за час работы',
    COURIERS_COST_KM: 'Цена за км. пути',
    COURIERS_COST_KGKM: 'Цена за кг./км. пути',
    COURIERS_COST_POINT: 'Цена за один адрес',
    COURIERS_VEHICLE_TYPE: 'Тип курьера',
    COURIERS_VEHICLE_TYPE_HINT: `Выбирая тип курьера учитывайте параметры груза, способ передвижения, запреты и ограничения движения по типам дорог и массе автомобилей, время въезда:<br/><br/>
    <b>Пеший курьер</b>: передвижение общественным транспортом или пешим способом<br/><br/>
    <b>Легковой автомобиль</b>: движение по всем типам дорог, если масса не превышает 1,5 т.<br/><br/>
    <b>Грузовой автомобиль</b>: максимальная грузоподъемность, могут быть ограничения въезда в определенные зоны при превышении разрешенной максимальной массы<br/><br/>`,
    COURIERS_DEPOT: 'Назначить склад',
    COURIERS_GARAGE: 'Назначить гараж',
    COURIERS_GARAGE_HINT: 'Выберите гараж или склад / гараж если курьер начинает  (и заканчивает) движение  с данных объектов',
    COURIERS_THROUGH_DEPOT: 'Заезжать на склад перед доставкой',
    COURIERS_LAST_POINT: 'Конечная точка маршрута',
    COURIERS_GET_WAYBILL: 'Скачать маршрутный лист',
    COURIERS_TEMPLATE_FILE_NAME: 'Импорт курьеров Zig-Zag.xlsx',
    COURIER_WORK_HINT: 'Режим работы курьера',
    COURIER_BREAK_HINT: 'Перерыв в рабочем времени курьера',
    COURIER_CARGO_HINT: 'Параметры груза в условных единицах. (кг, т, литры и др.) Если Вы измеряете груз в других единицах (например паллеты, бутыли и др.) укажите единицу измерения  перейдя в «Справочник единиц измерения» ',
    COURIER_PRICE_HINT: 'Расчет оплаты за работу курьера',
    COURIER_CLONE_SELECT: 'Копируемые курьеры',
    COURIER_CLONE_SELECT_HELP: 'Выберите курьеров, которые будут скопированны из справочника в текущий маршрут.',
    COURIER_CLONE_LINKED_POINTS: 'Добавить связанные точки',
    COURIER_CLONE_LINKED_POINTS_HELP: 'Если выбранные курьеры закреплены за какими-либо точками в спрвочнике, то они будут тоже скопированы',
    COURIERS_VEHICLE_TYPE_CAR: 'Легковой автомобиль',
    COURIERS_VEHICLE_TYPE_HGV: 'Грузовой автомобиль',
    COURIERS_VEHICLE_TYPE_FOOT: 'Пеший курьер',
    POINTS_TAB: 'Адреса текущего маршрута',
    POINTS_TAB_HINT: 'Адреса',
    POINTS_ADD_BUTTON: 'Адрес',
    POINTS_ADD_BUTTON_HINT: 'Добавить точку маршрута, режим работы и параметры груза точки маршрута',
    POINTS_ADD_BASE_BUTTON: 'Склад / Гараж',
    POINTS_ADD_BASE_BUTTON_HINT: 'Добавить склад / гараж, задать его адрес и режим работы',
    POINTS_INFO_TITLE: 'Данные точки',
    POINTS_INFO_WAYBILLS: 'Точки маршрутного листа',
    POINTS_INFO_WAYBILLS_MISSED: 'Точки с ошибками и предупреждениями',
    POINTS_EDIT_TITLE: 'Редактирование точки маршрута',
    POINTS_CREATE_TITLE: 'Создание точки маршрута',
    POINTS_EDIT_BASE_TITLE: 'Редактирование склада / гаража',
    POINTS_CREATE_BASE_TITLE: 'Создание склада / гаража',
    POINTS_LIST_TITLE: 'Список адресов',
    POINTS_DELETE_CONFIRM: 'Подтвердите удаление точки',
    POINTS_NAME: 'Наименование точки маршрута',
    POINTS_NAME_HINT: 'Назовите точку маршрута ("ТЦ «Город»", "Офис №56") ',
    POINTS_NAME_BASE: 'Название склада / гаража',
    POINTS_NAME_BASE_HINT: 'Введите название объекта ("Гараж №1", "Основной склад")',
    POINTS_ADDRESS: 'Адрес',
    POINTS_ADDRESS_HELP: `Адрес заполняется в следующей последовательности:<br/><br/>
      Населенный пункт, улица, дом/строение. Пример: "г. Москва, ул. Ленина, д.72" или "Москва, Ленина, 72"<br/><br/>
      Строка с адресом не должна содержать: номер телефона, режим работы, комментарии по оплате или приеме товара, прочие дополнения, не относящиеся к адресу.<br/><br/>
      Пример адреса, который не определится в системе: "Москва, Ленина, 72, вход со двора, приехать после 18 ч."<br/><br/>
      После ввода адреса Вам будет предложен список распознанных адресов. Выберите один из них для автоматического определения координат.
    `,
    POINTS_COORDS: 'Координаты',
    POINTS_COORDS_LAT: 'Широта',
    POINTS_COORDS_LON: 'Долгота',
    POINTS_COORDS_HELP: `При заполнении корректного адреса координаты заполняются автоматически. Если координаты не определились:<br/><br/>
    1. Введите их в ручном режиме, заполнив оба поля: "Широта" и "Долгота"<br/><br/>
    2. Проверьте расположение точки на карте и при необходимости передвиньте точку на нужный адрес, координаты обновятся автоматически`,
    POINTS_WEIGHT: 'Масса доставки груза',
    POINTS_VOLUME: 'Объём доставки груза',
    POINTS_DIRECT: 'Доставка',
    POINTS_BACKHAUL: 'Возврат',
    POINTS_BACKHAUL_WEIGHT: 'Масса возврата на склад',
    POINTS_BACKHAUL_VOLUME: 'Объём возврата на склад',
    POINTS_SHIPPING_TIME: 'Время обслуж.',
    POINTS_SHIPPING_TIME_HELP: 'Среднее время на погрузку/разгрузку в данной точке маршрута ',
    POINTS_SHIPPING_BASE_TIME: 'Время погрузки / разгрузки',
    POINTS_SHIPPING_BASE_TIME_HELP: 'Укажите примерное время на погрузку/разгрузку',
    POINTS_PRIORITY: 'Приоритет обслуж.',
    POINTS_PRIORITY_HINT: 'Добавьте приоритет обслуживания. Чем больше номер, тем выше приоритет по очередности выполнения  маршрута в данной точке. Диапазон приоритетов от 1 до максимального количества точек маршрута.',
    POINTS_COMMENT: 'Комментарий',
    POINTS_COMMENT_HINT: 'Дополнительные сведения для выполнения маршрута в данной точке (например: номер офиса/квартиры, название компании)',
    POINTS_GARAGE: 'Гараж',
    POINTS_DEPOT: 'Склад',
    POINTS_GARAGE_DEPOT_HINT: `Выберите создаваемый объект:<br/><br/>
      <b>Гараж</b> – место стоянки ТС<br/><br/>
      <b>Склад</b> – место погрузки/разгрузки.<br/><br/>
      Если у Вас один объект  выполняет функции и гаража и склада отметьте оба пункта.`,
    POINTS_COURIER: 'Обслуживающий курьер',
    POINTS_CONTACT_PERSON: 'Контактное лицо',
    POINTS_CONTACT_PERSON_HELP: 'ФИО контактного лица ответственного за погрузку/разгрузку ',
    POINTS_PHONE: 'Телефон',
    POINTS_CONTACT_HINT: 'Контактные данные для связи с ответственным лицом за погрузку/разгрузку',
    POINTS_COURIER_ID: 'Назначить курьера',
    POINTS_COURIER_HELP: `При назначении курьера  он выполняет маршрут в данной точке только один раз в течении выполнения одного маршрута.<br/><br/>
      Данное поле можно не заполнять, в этом случае курьер  будет назначен автоматически при построении маршрута.<br/><br/>
      При сбросе  деталей  маршрута  (количество  точек и курьеров,  время и километраж)  данные курьера  не сохранятся  в маршруте`,
    POINTS_ASSIGNED_COURIER_ID: 'Закрепить курьера',
    POINTS_ASSIGNED_COURIER_DELIVERY_HELP: `При закреплении курьера он автоматически  назначается на выполнение маршрута в данной точке.<br/><br/>
      При сбросе  деталей  маршрута  (количество  точек и курьеров,  время и километраж)  курьер останется закрепленным за этой точкой`,
    POINTS_ASSIGNED_COURIER_HELP: 'Выбранной курьер  будет начинать (и заканчивать)  маршрут в данном складе/гараже',
    POINTS_ASSIGNED_COURIER_LIST: 'Список закрепленных курьеров',
    POINTS_ASSIGNED_COURIER_LIST_HELP: 'Это поле отражает список курьеров, у которых данная точка выбрана гаражом. Его изменение ни на что не влияет, однако, можете выберать курьера, чтобы перейти к его карточке.',
    POINTS_DETAILS_TITLE: 'Данные по выделенным точкам',
    POINTS_TOTAL_WEIGHT: 'Суммарный вес к доставке',
    POINTS_TOTAL_VOLUME: 'Суммарный объем к доставке',
    POINTS_TOTAL_BACKHAUL_WEIGHT: 'Суммарный вес к возврату',
    POINTS_TOTAL_BACKHAUL_VOLUME: 'Суммарный объем к возврату',
    POINTS_SUM: 'Сумма',
    POINTS_SUM_HINT: 'Можете указать в этом поле значение суммы, не влияющие на расчет маршрута.',
    POINTS_COUNT: 'Кол-во выбранных точек:',
    POINTS_MASS_EDIT: 'Изменить все выбранные точки',
    POINTS_MASS_DELETE: 'Удалить выбранные точки',
    POINTS_CARGO_HELP: 'Параметры груза для доставки в данную точку в условных единицах (кг, т, литры и др.). Если не используете в расчетах, то оставьте "0". Если Вы измеряете груз в других единицах (например паллеты, бутыли и др.) укажите единицу измерения  перейдя в <a to="/catalog/measures">Справочник единиц измерения</a>',
    POINTS_BACKHAUL_CARGO_HELP: 'Параметры груза для возврата из данной точки. Работают только в задаче развоза. Если с данной точки необходимо забрать груз, то укажите соответствующие значения. В задаче с направлениями возвратный груз идёт на склад, даже если поставка с другой точки.',
    POINTS_CLONE_SELECT: 'Копируемые адреса',
    POINTS_CLONE_SELECT_HELP: 'Выберите адреса, которые будут скопированны из справочника в текущий маршрут.',
    POINTS_CLONE_LINKED_COURIERS: 'Добавить связанных курьеров',
    POINTS_CLONE_LINKED_COURIERS_HELP: 'Если за выбранными точками закреплены какие-либо курьеры из справочника, то они будут тоже скопированы',
    POINTS_CLONE_LINKED_ZONES: 'Добавить связанные зоны',
    POINTS_CLONE_LINKED_ZONES_HELP: 'Если выбранные точки закреплены за какими-либо зонами из справочника, то они тоже будут скопированы',
    POINTS_TRACK_LINK: 'Ссылка для отслеживания',
    SCHEDULE_WORK_START: 'Начало работы',
    SCHEDULE_WORK_END: 'Окончание работы',
    SCHEDULE_BREAK_START: 'Начало перерыва',
    SCHEDULE_BREAK_END: 'Окончание перерыва',
    POINTS_DELIVERY_SCHEDULE_HELP: 'Режим работы точки маршрута',
    POINTS_DELIVERY_BREAK_HELP: 'Перерыв в рабочем времени точки маршрута',
    POINTS_BASE_SCHEDULE_HELP: 'Режим работы склада / гаража',
    POINTS_BASE_BREAK_HELP: 'Перерыв в рабочем времени склада / гаража',
    POINTS_PICKUP: 'Промежуточный склад',
    POINTS_PICKUP_HELP: 'Выберите, если на данной точке находится груз для развоза в другие точки',
    POINTS_FROM: 'Источник груза',
    POINTS_FROM_HELP: 'Выберите точку, из которой необходимо доставить груз в текущую точку',
    POINTS_TEMPLATE_FILE_NAME: 'Импорт адресов Zig-Zag.xlsx',
    ZONES_TAB: 'Районы',
    ZONES_TAB_HINT: 'Районы',
    ZONES_LIST_TITLE: 'Список районов',
    ZONES_INFO_TITLE: 'Данные района',
    ZONES_ADD: 'Добавить новый район',
    ZONES_ADD_BUTTON: 'Добавить район',
    ZONES_GEOMETRY: 'Нарисовать район',
    ZONES_GEOMETRY_HELP: 'Деление территории на районы обслуживания. Нажмите кнопку "РИСОВАТЬ" и нарисуйте на карте многоугольник.',
    ZONES_COURIERS: 'Прикрепить курьеров',
    ZONES_COURIERS_HELP: 'Прикрепление курьера за районом обслуживания. Например, курьера можно прикрепить, если он хорошо ориентируется на данной территории, живет в этом районе или недалеко от него, выполнял маршрут в этом районе и уже знаком с клиентами',
    ZONES_POINTS: 'Привязать адреса',
    ZONES_POINTS_HELP: 'При импорте адресов точки маршрута автоматически привязываются к району согласно геопозиции. Если адреса проставлены без районов, то  их можно объединить в районы по нужным критериям (например: приоритетный район, режим работы только в будни)',
    ZONES_SET_GEOMETRY: 'Нарисовать район',
    ZONES_SELECT: 'Выбрать район',
    ZONES_CREATE_TITLE: 'Создать новый район',
    ZONES_EDIT_TITLE: 'Редактировать район',
    ZONES_NAME: 'Название района',
    ZONES_NAME_HINT: 'Введите название района для внесения его в систему и отображения на карте маршрута.',
    ZONES_DELETE_CONFIRM: 'Подтвердите удаление района',
    ZONES_CLONE_SELECT: 'Копируемые районы',
    ZONES_CLONE_SELECT_HELP: 'Выберите зоны, которые будут скопированны из справочника в текущий маршрут.',
    GROUPS_TAB: 'Группы',
    GROUPS_TAB_HINT: 'Группы',
    GROUPS_LIST_TITLE: 'Список групп',
    GROUPS_INFO_TITLE: 'Данные по группе',
    GROUPS_ADD: 'Добавить новую группу',
    GROUPS_ADD_BUTTON: 'Добавить группу',
    GROUPS_NAME: 'Название группы',
    GROUPS_SECTION: 'Тип элементов',
    GROUPS_SECTION_HELP: 'Выберите тип элементов, которые будут группированы',
    GROUPS_ENTITIES: 'Элементы',
    GROUPS_ENTITIES_HELP: 'Выберите элементы, относящиеся к данной группе.',
    GROUPS_SELECT: 'Выбрать группу',
    GROUPS_CREATE_TITLE: 'Создать новую группу',
    GROUPS_EDIT_TITLE: 'Редактировать группу',
    GROUPS_DELETE_CONFIRM: 'Подтвердите удаление района',
    GROUPS_CLONE_SELECT: 'Копируемые группы',
    GROUPS_CLONE_SELECT_HELP: 'Выберите группы, которые будут скопированны из справочника в текущий маршрут.',
    USERS_TAB: 'Пользователи',
    USERS_TAB_HINT: 'Пользов.',
    USERS_LIST_TITLE: 'Список пользователей',
    USERS_INFO_TITLE: 'Данные пользователя',
    USERS_ADD: 'Добавить нового пользователя',
    USERS_ADD_BUTTON: 'Добавить пользователя',
    USERS_ADD_BUTTON_HINT: 'Заполните  данные о пользователе для предоставления прав доступа к сервису и мобильному приложению',
    USERS_SELECT: 'Выбрать пользователя',
    USERS_CREATE_TITLE: 'Создать нового пользователя',
    USERS_EDIT_TITLE: 'Редактировать пользователя',
    USERS_NAME: 'Имя пользователя',
    USERS_NAME_HELP: 'Введите имя сотрудника (ФИО)',
    USERS_EMAIL: 'E-mail',
    USERS_EMAIL_HELP: 'Введите электронный адрес сотрудника для доступа в сервис. Он далжен быть вида your@email.ru. Можете оставить поле пустым, если создаете водителей, или пользователь не обладает особой ролью.',
    USERS_PASSWORD: 'Пароль',
    USERS_PASSWORD_HELP: 'Придумайте пароль для входа в личный кабинет сервиса Zig-Zag. Пароль должен быть не короче 5 символов, должен содержать латинские буквы и цифры.',
    USERS_PHONE: 'Телефон пользователя',
    USERS_PHONE_HELP: `Заполните номер телефона для авторизации и работы курьера в мобильном приложении Zig-Zag.<br/><br/>
      Hoмер должен состоять только из цифр, без дополнительных знаков и быть быть не короче 5 символов.<br/><br/>
      Пример: <b>89201002020</b>`,
    USERS_DRIVER: 'Курьер',
    USERS_DRIVER_HELP: 'Если сотрудник является курьером нажмите на данный пункт. Далее Вы можете заполнить график работы курьера в календаре',
    USERS_ROLE: 'Роль пользователя',
    USERS_ROLE_HELP: `Роли сотрудников для предоставления прав доступа к сервису внутри компании:<br/><br/>
      <b>Администратор</b> - полный доступ ко всем возможностям сервиса, в том числе назначение новых сотрудников на роли<br/><br/>
      <b>Управляющий</b> - возможность редактирования справочников, построения и удаления маршрутов<br/><br/>
      <b>Исполнитель</b> - просмотр справочников и маршрутов, без возможности их редактирования<br/><br/>`,
    USERS_SCHEDULE: 'График работы',
    USERS_SCHEDULE_HINT: 'Отметьте рабочие дни курьера в открывшемся календаре. Выбранные рабочие дни будут учтены при построении маршрута',
    USERS_DELETE_CONFIRM: 'Подтвердите удаление пользователя',
    FLAGS: 'Флаги соответствия',
    FLAGS_HELP: 'Выберите флаги соответсвия у курьеров и точек. Курьер поедет по адресу, только если выбранный флаг курьера выбран и у точки.<br/><br/>Вы можете обратиться в службу поддержки, чтобы установить уникальный список флагов по желанию.',
    MEASURES_TAB: 'Единицы изменения',
    MEASURES_TAB_HINT: 'Ед. изм.',
    MEASURES_LIST_TITLE: 'Список единиц изменения',
    MEASURES_INFO_TITLE: 'Данные единицы изменения',
    MEASURES_ADD: 'Добавить новую единицу измерения',
    MEASURES_ADD_BUTTON: 'Добавить новую ед. изм.',
    MEASURES_ADD_BUTTON_HELP: 'Добавление новой единицы измерения груза для точек маршрута (напр. паллеты, бутыли и др.). Если Вам нужны стандартные ед. изм. (кг, т, литры и др.), то они используются по умолчанию, данная функция не нужна.',
    MEASURES_SELECT: 'Выбрать для всех адресов',
    MEASURES_SELECT_HELP: 'Выбрать общую единицу измерения для всех точек маршрута.',
    MEASURES_CREATE_TITLE: 'Создать новую единицу измерения',
    MEASURES_EDIT_TITLE: 'Редактировать единицу измерения',
    MEASURES_NAME: 'Имя единицы измерения',
    MEASURES_DESCRIPTION: 'Описание единицы измерения',
    MEASURES_WEIGHT_UNITS: 'Равна единицам веса',
    MEASURES_VOLUME_UNITS: 'Равна единицам объема',
    MEASURES_UNITS_HELP: 'Укажите кол-во стандартных единиц веса/объема (целые числа или с точкой, напр. "10", "1.5", "0.2"), которые занимает новая единица.',
    MEASURES_DELETE_CONFIRM: 'Подтвердите удаление единицы измерения',
    MEASURES_APPLIED: 'Общая единица измерения',
    MEASURES_APPLIED_HINT: `Общая единица измерения для всех точек маршрута.
      В любой момент Вы можете создать и выбрать другую единицу измерения, а также использовать стандартные единицы измерения (кг, т, м3 и др.), выбрав \'- Не назначено -\', и используя их при заполнении данных <a to="/catalog/points">точкек маршрута</a>.`,
    DETAILS_TAB: 'Детали маршрута',
    DETAILS_TAB_HINT: 'Детали',
    DETAILS_STATUS_CALCULATED: 'Рассчитан',
    DETAILS_STATUS_PROCESSING: 'Идет вычисление',
    DETAILS_STATUS_ERROR: 'Ошибка в расчете',
    DETAILS_STATUS_ENQUEUED: 'Стоит в очереди на расчет',
    DETAILS_STATUS_READY: 'Готов к расчету',
    DETAILS_POINTS_COUNT: 'Кол-во точек',
    DETAILS_POINTS_COUNT_HINT: 'Количество точек маршрута, включая склады / гаражи и адреса погрузки/разгрузки',
    DETAILS_COURIERS_COUNT: 'Кол-во курьеров',
    DETAILS_COURIERS_COUNT_HINT: 'Количество курьеров, выполняющих маршрут',
    DETAILS_TOTAL_TIME: 'Общее время',
    DETAILS_TOTAL_TIME_HINT: 'Суммарное время выполнения маршрута всех курьеров',
    DETAILS_TOTAL_DISTANCE: 'Общий путь',
    DETAILS_TOTAL_DISTANCE_HINT: 'Общий километраж маршрута',
    DETAILS_TOTAL_WEIGHT: 'Общий вес',
    DETAILS_TOTAL_WEIGHT_HINT: 'Суммарный вес перевезенный в данном маршруте',
    DETAILS_TOTAL_COST: 'Стоимость маршрута',
    DETAILS_TOTAL_COST_HINT: 'Общая стоимость в условных единицах с учетом всех ценовых факторов каждого курьера (цена выезда, цена км. пути и т.д.)',
    DETAILS_CALC_TIME: 'Время расчета',
    DETAILS_CALC_TIME_HINT: 'Время работы расчетного модуля после последнего успешного запуска расчета',
    DETAILS_CLEAR_CALCULATION: 'Сбросить расчет',
    DETAILS_CLEAR_CALCULATION_CONFIRM: 'Все результаты расчёта, треки курьеров и сведения о выполненных точках будут уничтожены БЕЗВОЗВРАТНО. Сбросить маршрут?',
    DETAILS_CALCULATION_ERROR: 'Ошибка связи. Запустите расчет еще раз.',
    OPTIMIZATION_TAB: 'Оптимизация',
    OPTIMIZATION_TAB_HINT: 'Оптимиз.',
    OPTIMIZATION_RUN: 'Расчет маршрута',
    OPTIMIZATION_MANUAL: 'Редактир.',
    OPTIMIZATION_MANUAL_START: 'Начать редактирование маршрута вручную. После нажатия кликайте по точкам в соответствие с порядком маршрута. Для удаления точки из маршрута кликните по ней еще раз.',
    OPTIMIZATION_MANUAL_FINISH: 'Закончить редактирование маршрута',
    OPTIMIZATION_UPDATE: 'Дорассчитать маршрут',
    OPTIMIZATION_CONFIRM_RESET: 'Расчёт с ПОЛНЫМ СБРОСОМ текущего состояния маршрута',
    OPTIMIZATION_CONFIRM_UPDATE: 'Расчёт с СОХРАНЕНИЕМ посещённых и привязанных точек',
    OPTIMIZATION_CONFIRM_MANUAL: 'Оценка построенных вручную маршрутов',
    OPTIMIZATION_CONFIRM_STOP: 'Остановить процесс и восстановить последние рассчитанные данные?',
    OPTIMIZATION_CANCEL: 'Прервать расчет',
    OPTIMIZATION_FORM_TITLE: 'Параметры оптимизации',
    OPTIMIZATION_FORM_COMMON: 'Основные параметры',
    OPTIMIZATION_FORM_ADVANCED: 'Расширенные параметры',
    OPTIMIZATION_FORM_SUBMIT: 'Сохранить настройки',
    OPTIMIZATION_TASKNAME: 'Вид маршрута',
    OPTIMIZATION_CLUSTERIZE: 'Кластеризация',
    OPTIMIZATION_CLUSTERIZE_HELP: 'Если включена кластеризация, то задача разбивается на множество подзадач по кол-ву курьеров, и каждая подзадача считается отдельно. Точки объединяются на карте в небольшие группы, каждая из которых отводится одному из курьеров. Таким образом, каждый из курьеров работает в своей небольшой, автоматически построенной зоне.',
    OPTIMIZATION_SPECNAME: 'Параметр минимизации',
    OPTIMIZATION_SPECNAME_HELP: `Выберите минимизируемый параметр:<br/><br/>
      <b>Время между адресами:</b> Минимизирует время перемещения курьера между  адресами, не учитывая время от/до адресов «склад», «гараж»<br/><br/>
      <b>Расстояние между адресами:</b> Минимизирует  расстояние   между  адресами, не учитывая расстояние от/до адресов «склад», «гараж»<br/><br/>
      <b>Стоимость маршрута:</b> Программа уменьшает стоимость маршрута учитывая  заданные параметры курьера в справочнике <a to="/catalog/couriers">курьеры</a>:<br/>
      - Цена выезда на рейс<br/>
      - Цена за час работы<br/>
      - Цена за км. пути<br/>
      - Цена за кг./км. пути<br/>
      - Цена за один адрес<br/><br/>
      <b>Время всего маршрута:</b> Программа уменьшает  время выполнения маршрута в целом, вне зависимости от  пройденного километража. (например маршрут быстрее выполнить по объездной дороге, чем по пробкам).
      Этот параметр отличается от «Время между адресами» тем, что уменьшает общее время прохождения маршрута учитывая время движения от/до адреса «склад», «гараж» как при начале маршрута, так и при необходимости повторной погрузки/разгрузки.<br/><br/>
      <b>Задача направлений:</b> Особый тип задач, в которых курьеры одновременно развозят и собирают груз. Точки могут содержать груз для развоза.
      `,
    OPTIMIZATION_CLUSTERIZATION: 'Кластеризация',
    OPTIMIZATION_STRICT_MODE: 'Строгий режим',
    OPTIMIZATION_STRICT_MODE_HELP: 'Точки с проблемами в обслуживании (опоздание курьера, перегрузка машины...) не будут попадать в маршрутный лист.',
    OPTIMIZATION_NDAYS: 'Макс. количество дней в маршруте',
    OPTIMIZATION_MAXITER: 'Количество итераций расчёта',
    OPTIMIZATION_MAXITER_HELP: 'Количество повторений расчета маршрута для поиска самого оптимального варианта. Чем больше итераций, тем лучше результат, но дольше время расчета.',
    OPTIMIZATION_RUNS: 'Количество запусков расчёта',
    OPTIMIZATION_RUNS_HELP: 'Кол-во запусков расчета с выбранным кол-вом итераций/расчетов. Увеличение кол-ва запусков дает более надежный результат, но кратно увеличивает время расчета.',
    OPTIMIZATION_BYAGENT: 'По курьерам',
    OPTIMIZATION_BYZONE: 'По районам',
    OPTIMIZATION_BYZONE_HELP: 'Данный параметр используется для ускорения расчета большого количества адресов, путем деления на районы и последовательного расчета каждого района отдельно. Для работы с этим параметром необходимо создать районы на карте. Точки не попавшие в районы учитываются при построении маршрута в последнюю очередь.',
    OPTIMIZATION_NNEIGHBORS: 'Кол-во рассм. соседних точек',
    OPTIMIZATION_IMPROVE_CLUSTERS: 'Улучшать кластеры',
    OPTIMIZATION_BALANCE_BY_WEIGHT: 'Выравнивание кластеров по весу',
    OPTIMIZATION_DONT_MAKE_ROUTES: 'Не создавать маршруты из кластеров',
    OPTIMIZATION_TASK: 'Тип маршрута',
    OPTIMIZATION_TASK_HELP: `Выберите тип выполнения задачи маршрута:<br/><br/>
    <b>Сбор груза</b> – включает возврат на склад в случае полной загрузки автомобиля и после посещения последнего адреса.<br/><br/>
    <b>Развоз груза</b> – курьер всегда начинает движение со склада и возвращается на склад при полной разгрузке на маршруте.`,
    OPTIMIZATION_TASK_OPTION_DISTRIBUTION: 'Развоз груза',
    OPTIMIZATION_TASK_OPTION_COLLECT: 'Сбор груза',
    OPTIMIZATION_CROW_MATRICES: 'Расстояния по прямым линиям',
    OPTIMIZATION_SKIP_ROUTES: 'Не составлять геометрию маршрутов',
    OPTIMIZATION_SPEED_MULTIPLIER: 'Коэффициент скорости',
    OPTIMIZATION_SPEED_MULTIPLIER_HELP: 'Искусственное замедление или увеличение скорости курьера, например по причинам пробок, ухудшения погодных условий и т.д. Средняя скорость автомобиля умножается на коэффициент скорости. (напр.: средняя скорость 25 км/ч, коэффициент скорости 2 – скорость 50 км/ч, коэффициент 0,5  - скорость 12,5 км/ч).',
    OPTIMIZATION_TRAFFIC_JAMS: 'Учитывать пробки',
    OPTIMIZATION_TRAFFIC_JAMS_HELP: 'Замедление времени выполнения маршрута с учетом возможных пробок из статистических данных сервиса Yandex.Карты',
    OPTIMIZATION_PRESERVE_COURIERS: 'Сохранить данные курьеров',
    OPTIMIZATION_PRESERVE_COURIERS_HELP: 'После выполнения расчета маршрута точки будут закреплены за курьерами. При последующих расчетах маршрута данные точки останутся закрепленными за курьерами. В основном используется для оперативного вмешательства в существующий маршрут, например для удаления/добавления адресов в рамках действующего маршрутного листа. Пользователь может оперативно определить адреса, которые не смогут быть посещены в запланированное время понижая коэффициент скорости движения.',
    OPTIMIZATION_BALANCE_AGENTS: 'Балансировать по курьерам',
    OPTIMIZATION_BALANCE_AGENTS_HELP: 'Программа стремится распределить точки маршрута между курьерами для выравнивания времени их работы. Чем больше значение, тем меньше разница во времени работы между курьерами.',
    OPTIMIZATION_TRUCKER_DAYS: 'Кол-во дней в дальних рейсах',
    OPTIMIZATION_TRUCKER_DAYS_HELP: 'Для всех курьеров, у которых выбрано "Многоднейвный маршрут" устанавливает максимальное кол-во дней рейса, чтобы избежать слишком долгих маршрутов.',
    OPTIMIZATION_BY_GROUP: 'Автоматическая кластеризация',
    OPTIMIZATION_BY_GROUP_HELP: 'Оптимизация для больших задач.<br/><br/>Разбивает задачу на группы точек и добавляет их в расчёт по одной, фиксируя привязки к курьерам c расчёта предыдущих групп в соответствии с типом и флагами оптимизации.',
    OPTIMIZATION_OPTION_CLUSTERIZE: 'Кластеризация',
    OPTIMIZATION_OPTION_OPTIMIZE: 'Оптимизация',
    OPTIMIZATION_OPTION_MAXWEIGHT: 'Брать Макс. вес',
    OPTIMIZATION_OPTION_MINCLUSTER: 'Время между адресами',
    OPTIMIZATION_OPTION_MINDISTCLUSTER: 'Расстояние между адресами',
    OPTIMIZATION_OPTION_MINTIME: 'Время всего маршрута',
    OPTIMIZATION_OPTION_MINCOST: 'Стоимость маршрута',
    OPTIMIZATION_OPTION_DIRECTIONS: 'Задача направлений',
    OPTIMIZATION_OPTION_AUTO: 'Автоматически',
    OPTIMIZATION_OPTION_MANUAL: 'Ручной режим',
    OPTIMIZATION_OPTION_NONE: 'По умолчанию',
    CUSTOM_FORM_TAB: 'Клиентская интеграция',
    SUBMIT_DEFAULT: 'Готово',
    SUBMIT_SAVE: 'Сохранить',
    SUBMIT_CREATE: 'Создать',
    SUBMIT_SEND: 'Отправить',
    SUBMIT_CANCEL: 'Отмена',
    SUBMIT_DOWNLOAD: 'Скачать',
    SUBMIT_EXECUTE: 'Выполнить',
    FILTERS_TITLE: 'Фильтр по названию',
    FILTERS_DATE: 'Фильтр по дате',
    ITEM_DELETE: 'Удалить',
    ITEM_EDIT: 'Редактировать',
    ITEM_CLONE: 'Клонировать',
    ITEM_POWER_OFF: 'Выключить',
    ITEM_POWER_ON: 'Включить',
    ITEM_EDIT_WAYBILL: 'Редактировать маршрут',
    ITEM_GROUP_IMPORT: 'Импортировать в группу',
    FIELD_NO_OPTIONS: 'Нет опций для выбора',
    FIELD_NO_DATA: 'Нет данных',
    FIELD_NO_ITEM: 'Не выбрано',
    FIELD_SELECTED_COUNT: 'Выбрано',
    FIELD_BEGIN_DRAW: 'Рисовать',
    FIELD_STOP_DRAW: 'Закончить',
    FIELD_START_SELECT: 'Выбрать',
    FIELD_START_CALENDAR: 'Календарь',
    FIELD_SELECT_ITEMS: 'Выберите элементы',
    FIELD_SELECT_FLAGS: 'Выберите флаги',
    FIELD_SELECT_DAYS: 'Выберите даты',
    FIELD_NULL_VALUE: '- Не назначено -',
    FIELD_UNDEFINED_VALUE: '- Не менять -',
    FIELD_HELP: 'Помощь',
    FIELD_CUSTOM_ACTION: 'Выполнить',
    FIELD_EXTRA_FIELDS: 'Дополнительные поля',
    WAYBILLS_FIELD_COURIER: 'Название курьера',
    WAYBILLS_FIELD_ADDRESS: 'Адрес точки',
    WAYBILLS_FIELD_CONTACT_PERSON: 'Контактное лицо',
    WAYBILLS_FIELD_PHONE: 'Телефон',
    WAYBILLS_FIELD_POINT_COMMENT: 'Комментарий к точке',
    WAYBILLS_FIELD_DRIVER_COMMENT: 'Комментарий водителя',
    WAYBILLS_FIELD_WORK_START: 'Начало работы',
    WAYBILLS_FIELD_WORK_END: 'Окончание работы',
    WAYBILLS_FIELD_BREAK_START: 'Начало перерыва',
    WAYBILLS_FIELD_BREAK_END: 'Окончание перерыва',
    WAYBILLS_ARRIVAL: 'Расчетное прибытие',
    WAYBILLS_DEPARTURE: 'Расчетное отбытие',
    WAYBILLS_WEIGHT: 'Вес груза клиента',
    WAYBILLS_WEIGHT_LOADED: 'Вес груза в кузове',
    WAYBILLS_VOLUME: 'Объем груза клиента',
    WAYBILLS_VOLUME_LOADED: 'Объем груза в кузове',
    WAYBILLS_TOTAL_DISTANCE: 'Суммарный путь (км.)',
    WAYBILLS_TOTAL_TIME: 'Суммарное время',
    WAYBILLS_TOTAL_COST: 'Суммарная стоимость',
    WAYBILLS_COMING_TIME: 'Время изменение статуса',
    WAYBILLS_STATUS: 'Статус',
    WAYBILLS_STATUS_CODE_1: 'В работе',
    WAYBILLS_STATUS_CODE_2: 'Выполнен',
    WAYBILLS_STATUS_CODE_5: 'Отказ',
    WAYBILLS_SUM: 'Сумма',
    WAYBILLS_PHOTO: 'Контрольные фото',
    WAYBILLS_ORDER: 'Порядок следования',
    WAYBILLS_ROUTE_CODE: 'Код состояния ',
    WAYBILLS_ROUTE_CODE_ST: 'Начало движения',
    WAYBILLS_ROUTE_CODE_UL: 'Погрузка / разгрузка',
    WAYBILLS_ROUTE_CODE_RL: 'Погрузка',
    WAYBILLS_ROUTE_CODE_RU: 'Разгрузка',
    WAYBILLS_ROUTE_CODE_LD: 'Погрузка для развоза по направлениям',
    WAYBILLS_ROUTE_CODE_UD: 'Разгрузка по направлениям',
    WAYBILLS_ROUTE_CODE_RG: 'Движение по маршруту',
    WAYBILLS_ROUTE_CODE_RT: 'Конец маршрута',
    WAYBILLS_ROUTE_CODE_EW: 'Возврат в гараж в конце рабочего дня',
    WAYBILLS_ROUTE_CODE_BR: 'Перерыв',
    WAYBILLS_ROUTE_CODE_LH: 'Ночевка при движении в точку',
    WAYBILLS_ROUTE_CODE_SR: 'Обслуживание точки',
    WAYBILLS_ROUTE_CODE_ERR: 'Ошибка в маршруте',
    WAYBILLS_ROUTE_CODE_T: 'Точка недостижима в рабочее время',
    WAYBILLS_ROUTE_CODE_W: 'Вес точки превышает грузоподъемность',
    WAYBILLS_ROUTE_CODE_S: 'Точка не поместилась в расписание',
    WAYBILLS_ROUTE_CODE_L: 'Курьер не попадает во рабочее время точки',
    WAYBILLS_ROUTE_CODE_OL: 'Обслуживание точки приведет к перегрузу',
    WAYBILLS_ROUTE_CODE_OLD: 'Груз для точки не загружен',
    WAYBILLS_ROUTE_CODE_WZ: 'Точка лежит вне зоны курьера',
    WAYBILLS_ROUTE_CODE_WV: 'Неподходящий тип курьра для обслуживания',
    WAYBILLS_ROUTE_CODE_PV: 'Нарушение приоритетности точек',
    WAYBILLS_ROUTE_CODE_NW: 'Не найден путь до точки в дорожном графе',
    WAYBILLS_ROUTE_CODE_NL: 'Последняя точка в маршруте не соответствует заданной у курьера',
    WAYBILLS_ROUTE_CODE_ND: 'Склад недоступен',
    EDIT_WAYBILLS_TITLE: 'Ручное редактирование маршрута',
    EDIT_WAYBILLS_COURIERS_FREE: 'Свободных маршрутов',
    EDIT_WAYBILLS_COURIERS_EDITED: 'Измененных маршрутов',
    EDIT_WAYBILLS_RESET_CURRENT: 'Сбросить текущий',
    EDIT_WAYBILLS_RESET_ALL: 'Сбросить все',
    EDIT_WAYBILLS_SAVE_WAYBILL: 'Сохранить маршрут',
    EDIT_WAYBILLS_NO_COURIERS_ERROR: 'Не найдено ни одного курьера для редактирования',
    EDIT_WAYBILLS_LINK: 'Редактировать маршруты',
    LOGIN_AUTORIZATION: 'Авторизация',
    LOGIN_LOGIN: 'Войти',
    LOGIN_LOGOUT: 'Выйти',
    LOGIN_SIGNUP: 'Регистрация',
    LOGIN_HELLO: 'Привет, ',
    LOGIN_RESTORE_PASSWORD: 'Восстановить пароль',
    USER_NAME: 'Логин',
    USER_PHONE: 'Телефон',
    USER_PASSWORD: 'Пароль',
    USER_CONFIRM_PASSWORD: 'Подтвердите пароль',
    USER_EMAIL: 'E-mail',
    MISC_TEMPLATE_WORKSHEET: 'Шаблон',
    MISC_TEMPLATE_SHEET_NAME: 'Импорт',
    MISC_TEMPLATE_TITLE: 'Импорт адресов для расчета сервисом zig-zag.org.',
    MISC_TEMPLATE_WARNING_TITLE: 'Не удаляйте строки ниже, заполните данные, начиная с пустой строки.',
    MISC_TEMPLATE_DESCRIPTION: 'Широта и долгота - необязательные поля. Координаты без них сами определятся из адреса. Если адрес является вашим складом / гаражом, то заполните соотв. поля цифрой "1".',
    MISC_WAYBILLS_MANIFEST: 'Маршрутные листы маршрута (*).xlsx',
    MISC_WAYBILLS_COURIER: 'Маршрутные листы курьера (*).xlsx',
    MISC_WAYBILLS_SHEET_ALL: 'Все курьеры (общая статистика)',
    MISC_WAYBILLS_SHEET_ALL_DETAILED: 'Все курьеры (детально)',
    MISC_LIST_NO_ITEMS: 'Ничего не найдено',
    MISC_ITEM_CURRENT_MANIFEST: 'Создать в тек. маршруте',
    MISC_ITEM_FROM_CATALOG: 'Добавить из справочника',
    MISC_SELECT_ALL: 'Выбрать все',
    MISC_UNSELECT_ALL: 'Убрать все',
    MISC_DROP_DOWN_CONTAINER: 'Скрытое содержимое, нажмите, чтобы увидеть',
    MISC_DELETE_MULTIPLE: 'Удалить несколько элементов',
    MISC_DELETE_MULTIPLE_CONFIRM: 'Удалить выбранные элементы?',
    MISC_IMPORT_XLS: 'Импорт',
    MISC_IMPORT_XLS_HINT: 'Загрузите  заполненный шаблон со списком элементов в формате XLS. Скачать шаблон для заполнения можно нажав на соседнюю кнопку.',
    MISC_DOWNLOAD_XLS: 'Скачать шаблон XLS',
    MISC_DOWNLOAD_XLS_HINT: 'Скачайте шаблон для заполнения списка элементов в формате XLS и последующей загрузки',
    NOTIFY_INPROCESS: 'Операция выполняется',
    NOTIFY_ERROR: 'Произошла ошибка.',
    NOTIFY_SUCCESS: 'Успешно.',
    NOTIFY_DELETE: 'Удаление элемента.',
    NOTIFY_NO_DATA: 'Нет данных.',
    NOTIFY_IMPORT_POINTS_ERROR_DATA: 'Файл поврежден или шаблон заполнен некорректно.',
    NOTIFY_IMPORT_POINTS_ERROR_EMPTY: 'Не найдено ни одного элента для импорта.',
    NOTIFY_IMPORT_POINTS_ERROR_LINE: 'Строка: ',
    NOTIFY_IMPORT_POINTS_ERROR_FIELD: 'Поле: ',
    NOTIFY_IMPORT_POINTS_ERROR_NO_FIELD: 'Невозможно импортировать поле:',
    NOTIFY_IMPORT_TIMEOUT: 'Извините, у нас временное ограничение на импорт файлов из-за высокой нагрузки. Следующий импорт возможен:',
    NOTIFY_SUBSCRIPTION_EXPIRES: 'Пожалуйста, оплатите подписку на следующий период. Доступ в систему будет приостановлен',
    ERROR_C001: 'Пароли должны совпадать',
    ERROR_C002: 'Сервер не отвечает, пожалуйста свяжитесь с менеджером',
    ERROR_C003: 'Неверное имя пользователя или пароль',
    ERROR_C004: 'Ошибка регистрации, проверьте введенные данные',
    ERROR_C005: 'Заполните все поля',
    ERROR_C006: 'Регистрация прошла успешно. Скоро с Вами свяжется наш менеджер, чтобы провести ознакомление с возможностями сервиса. Пользователь еще не активен.',
    ERROR_1000: 'Внутренняя ошибка сервера приложений',
    ERROR_1001: 'Пользователь не найден или неверный пароль.',
    ERROR_1002: 'Email не найден',
    ERROR_1003: 'Пароль не найден',
    ERROR_1004: 'Неизвестный формат данных: %format%, требуется "json" или "xml"',
    ERROR_1005: 'Незвестная операция: %operation%',
    ERROR_1006: 'Нет прав доступа к текущему манифесту',
    ERROR_1007: 'Нет прав доступа к текущему каталогу',
    ERROR_1008: 'Некоректный или недостающий параеметр: %param_name%',
    ERROR_1009: 'Множественные ошибки сервера приложений',
    ERROR_1010: 'Сущность %id% не найдена',
    ERROR_1011: 'Запрошенная задача не выполнена',
    ERROR_1012: 'Маршрут уже находится в процессе рассчета',
    ERROR_1013: 'Запрещенная операция',
    ERROR_1014: 'Данный Email уже зарегистрирован',
    ERROR_1015: 'Укажите номер телефона',
    ERROR_1016: 'Ошибка формарирования отчета',
    ERROR_1017: 'Пользователь заблокирован, пожалуйста, свяжитесь с менеджером',
    ERROR_1018: 'Данный номер телефона уже зарегистрирован',
    ERROR_2000: 'Внутренняя ошибка очереди задач',
    ERROR_2001: 'Роутер не ответил',
    ERROR_2002: 'Ошибка роутера: %message%',
    ERROR_2003: 'Маршрут не содержит курьеров',
    ERROR_2004: 'Маршрут не содержит точки',
    ERROR_3000: 'Внутренняя ошибка рассчетного модуля',
    ERROR_3001: 'Неизвестная спецификация',
    ERROR_3002: 'Неизвестная задача',
    ERROR_3003: 'Точки %point_id% не попала в кластер',
    ERROR_3004: 'Поле %field_name% не заполнено (курьер: %agent_id%, точка: %point_id%)',
    ERROR_3005: 'Неизвестная зона %zone_id% (курьер: %agent_id%, точка: %point_id%)',
    ERROR_3006: 'Слишком много зон в маргруте, 63 максимум',
    ERROR_3007: 'Задача распредения по зонам требует наличия только одной зоны у точки %point_id%',
    ERROR_3008: 'Кол-во рассм. соседних точек должно быть не менее 1',
    ERROR_3009: 'Маршрут не содержит складов',
    ERROR_3010: 'Нет доступа к серверу лицензий',
    ERROR_3011: 'Проблемы с лицензией',
    ERROR_3012: 'IP заблокирован',
    ERROR_3013: 'Превышение допустимых лимитов задачи',
    ERROR_3014: 'Unknown point (курьер: %agent_id%, точка: %point_id%)',
    ERROR_3015: 'Unknown agent (курьер: %agent_id%, точка: %point_id%)',
    ERROR_CODE_UNKNOWN: 'Неизвестный код ошибки',
    DAYPICKER_WEEKDAYS_SHORT: [
      'Вс',
      'Пн',
      'Вт',
      'Ср',
      'Чт',
      'Пт',
      'Сб'
    ],
    DAYPICKER_MONTHS: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    DAYPICKER_MONTHS_PARENTAL: [
      'Января',
      'Февраля',
      'Марта',
      'Апреля',
      'Мая',
      'Июня',
      'Июля',
      'Августа',
      'Сентября',
      'Октября',
      'Ноября',
      'Декабря'
    ],
    DAYPICKER_WEEKDAYS_LONG: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота'
    ],
    DAYPICKER_FIRST_DAY_OF_WEEK: 1,
    DAYPICKER_LABELS: {
      'nextMonth': 'Следующий месяц',
      'previousMonth': 'Предыдущий месяц'
    },
    DEFAULT_FLAGS: [
      'Красный',
      'Оранжевый',
      'Жёлтый',
      'Зелёный',
      'Голубой',
      'Синий',
      'Фиолетовый',
      'Серебристый',
      'Белый',
      'Розовый',
      'Золотой',
      'Каштановый',
      'Коричневый',
      'Бурый',
      'Оливковый',
      'Болотный',
      'Травяной',
      'Бирюзовый',
      'Аквамарин',
      'Малиновый',
      'Пурпурный',
      'Пунцовый',
      'Алый',
      'Циан',
      'Бордо',
      'Вишнёвый',
      'Шоколадный',
      'Бежевый',
      'Серый',
      'Черный'
    ]
  },

  'us': {
    DISTANCE_HIGH_ABBR_KM: 'km',
    DISTANCE_LOW_ABBR_KM: 'ft',
    DISTANCE_HIGH_ABBR_MI: 'mi',
    DISTANCE_LOW_ABBR_MI: 'f',
    WEIGHT_HIGH_ABBR_KG: 't',
    WEIGHT_LOW_ABBR_KG: 'kg',
    WEIGHT_HIGH_ABBR_LB: 't',
    WEIGHT_LOW_ABBR_LB: 'lb',
    SETTINGS_LANGUAGE: 'Language',
    SETTINGS_MEASURES: 'Units',
    SETTINGS_TIMES: 'Time Format',
    SETTINGS_LANGUAGES: {
      // 'ru': 'Russian',
      'us': 'English (US)'
    },
    SETTINGS_MEASURE_UNITS: {
      'metric': 'Metric',
      'us': 'Imperial (US)'
    },
    SETTINGS_TIME: {
      '1': '12-hour time',
      '0': '24-hour time'
    },
    CATALOG_DOCUMENTS: 'Documents',
    POINTS_TOTAL_SHIPPING_TIME: 'Total service time',
    POINTS_DAY: 'Day',
    POINTS_DAY_HINT: 'Day of the week for the route',
    POINTS_MANDATOTY: 'Must-visit',
    POINTS_MANDATOTY_HELP: 'Mandatory to visit - assigned to those points that must be visited in mandatory. At the same time, the route is built in an optimal way. This point will be in an arbitrary ordinal place in the itinerary. ',
    POINTS_CURBSIDE: 'Entrance to the point',
    POINTS_CURBSIDE_HELP: 'Select the side of the car from which the courier will approach the point',
    POINTS_CURBSIDE_RIGHT: 'Drive up on the right',
    POINTS_CURBSIDE_LEFT: 'Drive up from the left',
    ITEM_DOCUMENT_SIGNED: 'Sign',
    ITEM_DOCUMENT_DOWNLOAD: 'Upload',
    DOCUMENT_INFO_TITLE: 'List of documents',
    DOCUMENT_SIGN_CONFIRM: 'When signing the document, you certainly accept the terms of the Offer Agreement',
    NOTIFY_UNSIGN_DOCUMENT: 'You have unsigned documents, please sign the Main menu/References/Documents',
    HOURS_ABBR: 'h',
    MINUTES_ABBR: 'm',
    DAY_ABBR: 'd',
    AREA_HECTARE: 'Hectare:',
    DATES_CLOSEST: 'Coming days:',
    DATES_ALL: 'Dates:',
    MANIFEST_TITLE: 'Route management',
    MANIFEST_LIST_TITLE: 'Route List',
    MANIFEST_ADD: 'Add a route',
    MANIFEST_SELECT: 'Choose a route',
    MANIFEST_STATUS_ENQUEUED: 'In line',
    MANIFEST_STATUS_SUCCESS: 'Route calculated',
    MANIFEST_STATUS_WARNING: 'Route calculated with warnings',
    MANIFEST_STATUS_ERROR: 'Calculation error',
    MANIFEST_STATUS_PREPARING: 'Matrix calculation',
    MANIFEST_STATUS_WAITING: 'Waiting',
    MANIFEST_STATUS_PROCESSING: 'Route calculation',
    MANIFEST_STATUS_MISSED_POINTS: 'There are erroneous points',
    MANIFEST_CREATE_TITLE: 'Create a new route',
    MANIFEST_CLONE_TITLE: 'Clone current route',
    MANIFEST_EDIT_TITLE: 'Route editing',
    MANIFEST_NAME: 'Route name',
    MANIFEST_CURRENT: 'Current route:',
    MANIFEST_NAME_HELP: 'Working name of the route for recognizing it in the Zig-Zag system',
    MANIFEST_DATE: 'Route start date',
    MANIFEST_SETTINGS: 'Settings',
    MANIFEST_SPECAIL_POINTS: 'Base addresses',
    MANIFEST_REGULAR_POINTS: 'Route points',
    MANIFEST_COURIERS: 'Couriers',
    MANIFEST_ZONES: 'Areas',
    MANIFEST_DELETE_CONFIRM: 'Confirm route deletion',
    MANIFEST_GET_WAYBILLS: 'Download route sheets',
    MANIFEST_GET_WAYBILLS_HINT: 'Download route sheets of all couriers',
    MANIFEST_FIRST_MESSAGE: 'We have no routes, let`s create the first one.',
    MANIFEST_NO_MAP_POINTS: 'The route has been created, but we do not have points with coordinates, let`s add one.',
    MANIFEST_EDIT_HINT: 'Change the parameters of the current route',
    MANIFEST_CLONE_HINT: 'Building a new route with the ability of full or partial copying the parameters of the current route',
    MANIFEST_DELETE_HINT: 'Permanent removal of the current route from the system',
    MANIFEST_MODE_MANUAL: 'Manual',
    MANIFEST_MODE_MANUAL_HINT: 'Manual editing mode is on. Click the button on the right to manually build a route.',
    MANIFEST_MODE_AUTO: 'Automatic',
    MANIFEST_MODE_AUTO_HINT: 'Automatic route building mode is on. Click the button on the right to calculate the optimal route',
    CATALOG_TITLE: 'Directories',
    CATALOG_COURIERS: 'Courier Guide',
    CATALOG_USERS: 'User Reference',
    CATALOG_POINTS: 'Directory of addresses',
    CATALOG_ZONES: 'Directory of districts',
    CATALOG_MEASURES: 'Directory of measurement units',
    CATALOG_GROUPS: 'Directory of element groups',
    MAP_VIEW_MAP: 'Transparency map. The ability to make the base map more transparent, to focus on the layer with the constructed route.',
    MAP_VIEW_POINTS: 'Display dots. Show or hide all route points on the map except the base ones (warehouse / garage) to make it easier to select them on the map. The point number indicates the order of route execution.',
    MAP_VIEW_ASSIGNED_POINTS: 'Display points assigned to couriers. It’s convenient when the points are distributed manually, the extra ones disappear after the assignment.',
    MAP_VIEW_CLUSTERS: 'Grouping points. When the map scale changes, neighboring points are grouped or divided one by one. When combining points, they are assigned a number equal to the number of points in the group.',
    MAP_VIEW_LINES: 'Show straight lines. Connection of route points directly (dashed lines) without taking into account the constructed route.',
    MAP_VIEW_PATH: 'Display route lines. Displays the lines of the constructed route.',
    MAP_VIEW_ZONES: 'Display areas. Show or hide previously created areas.',
    MAP_VIEW_TRACKS: 'Show tracks. Displays the actual location of the courier during the route. It`s possible when using the courier mobile application only.',
    MAP_CONTROLS_SELECT: 'Select points on the map. Highlighting points with an area allows you to view and edit data about them (operating mode, cargo parameters, couriers)',
    MAP_CONTROLS_UNSELECT: 'Finish and deselect',
    MANIFEST_TAB: 'Routes',
    MANIFEST_TAB_HINT: 'Route List',
    ORDERS_TAB: 'Applications',
    ORDERS_TAB_HINT: 'List of applications',
    HISTORY_TAB: 'History',
    HISTORY_TAB_HINT: 'История чего-то',
    REPORTS_TAB: 'Reports',
    REPORTS_TAB_HINT: 'Reports on completed routes',
    DICTS_TAB: 'Directories',
    DICTS_TAB_HINT: 'Basic information (about couriers, vehicles, addresses and districts) to create a route. When building a route, the fields can be automatically filled with data from directories',
    SETTINGS_TAB: 'Settings',
    SETTINGS_TAB_HINT: 'Settings',
    YOUTUBE_TAB: 'Training',
    YOUTUBE_TAB_HINT: 'Watch the tutorials on our YouTube channel',
    SUPPORT_TAB: 'Help',
    SUPPORT_TAB_HINT: 'Have questions? Click to contact our specialist via email<b>support@zig-zag.org</b>or call tel.<b>8 (800) 250-41-43</b>',
    COURIERS_TAB: 'Available couriers',
    COURIERS_TAB_HINT: 'Couriers',
    COURIERS_NAME: 'Courier Name',
    COURIERS_NAME_HINT: 'Enter the courier name (for example: name of the courier, license plate of the vehicle, nickname)',
    COURIERS_ADD_BUTTON: 'Add a courier',
    COURIERS_ADD_BUTTON_HINT: 'In this section, select the type of courier (courier / foot courier) and enter data on the mode of operation, calculation of payment for work, cargo parameters',
    COURIERS_INFO_TITLE: 'Courier Details',
    COURIERS_PHONE: 'Contact phone number',
    COURIERS_INFO_WAYBILLS: 'Route sheet points',
    COURIERS_EDIT_TITLE: 'Edit a courier',
    COURIERS_CREATE_TITLE: 'Create a courier',
    COURIERS_LIST_TITLE: 'Courier List',
    COURIERS_DELETE_CONFIRM: 'Confirm courier removal',
    COURIERS_POWER: 'If you turn it off, the vehicle does not fall into the calculation, but the history of visits to route points is saved. For example, if a vehicle breaks down, you need to recalculate the route based on the addresses it visited.',
    COURIERS_MAX_VOLUME: 'Max. cargo volume',
    COURIERS_MAX_WEIGHT: 'Max. cargo weight',
    COURIERS_MAX_POINTS: 'Max. number of addresses',
    COURIERS_MAX_POINTS_HINT: 'Maximum number of addresses per working day',
    COURIERS_DRIVER: 'Assign a courier',
    COURIERS_DRIVER_HINT: 'Assigning a courier you distribute the route to him and send the route sheet to the mobile application',
    COURIERS_CURRENT_POINT: 'Start of execution',
    COURIERS_CURRENT_POINT_HINT: `The starting point of the courier's movement along the route can be:<br/><br/>
     <b>1. Garage</b><br/>
     <b>2. Warehouse / garage</b><br/>
     <b>3. Warehouse</b>`,
    COURIERS_CURRENT_WEIGHT: 'Starting cargo weight',
    COURIERS_CURRENT_VOLUME: 'Starting cargo volume',
    COURIERS_CURRENT_TIME: 'Start time of execution',
    COURIERS_CURRENT_DISTANCE: 'Initial distance traveled',
    COURIERS_CURRENT_COST: 'Initial cost of the delivered cargo',
    COURIERS_NOT_RETURNING: 'Finish at the last point of the route',
    COURIERS_NOT_RETURNING_HINT: 'Calculation and construction of the route is made to the last point of the route. After the route is completed, a courier does not return to the garage and/or warehouse',
    COURIERS_SINGLE_RUN: 'One departure from the warehouse',
    COURIERS_SINGLE_RUN_HINT: 'If you choose this item, a courier leaves the warehouse only once during the working day. During the assignment, the courier is not expected to return to the warehouse for additional loading.',
    COURIERS_LONGHAUL: 'Multi day route',
    COURIERS_LONGHAUL_HINT: 'The route is more than 24 hours long. It is built taking into account stops for rest and overnight.',
    COURIERS_OUTOFORDER: 'Exclude from route',
    COURIERS_OUTOFORDER_HINT: 'If selected, then a vehicle does not fall into the calculation, but the history of visits to points on the route is saved. Usage example: a vehicle broke down, it is necessary to recalculate the route taking into account the addresses it visited.',
    COURIERS_COST_ROUND: 'Departure route price',
    COURIERS_COST_HOUR: 'Price per working hour',
    COURIERS_COST_KM: 'Price per km.',
    COURIERS_COST_KGKM: 'Price per kg./km.',
    COURIERS_COST_POINT: 'Price per one address',
    COURIERS_VEHICLE_TYPE: 'Courier Type',
    COURIERS_VEHICLE_TYPE_HINT: `When choosing the courier type, consider the parameters of the cargo, the method of movement, the prohibitions and restrictions on the types of roads and the mass of vehicles, time of entry:<br/><br/>
      <b>Walking courier</b>: public transport or on foot<br/><br/>
      <b>Passenger car</b>:driving on all types of roads if the mass does not exceed 1.5 t.<br/><br/>
      <b>Truck</b>:maximum load capacity, there may be restrictions on entry into certain areas when exceeding the permissible maximum weight<br/><br/>`,
    COURIERS_DEPOT: 'Assign a warehouse',
    COURIERS_GARAGE: 'Assign a garage',
    COURIERS_GARAGE_HINT: 'Choose a garage or warehouse if a courier starts (and ends) movement with these objects',
    COURIERS_THROUGH_DEPOT: 'Come through the warehouse before delivery',
    COURIERS_LAST_POINT: 'End point of the route',
    COURIERS_GET_WAYBILL: 'Download the route sheet',
    COURIERS_TEMPLATE_FILE_NAME: 'Import couriers Zig-Zag.xlsx',
    COURIER_WORK_HINT: 'Courier mode of operation',
    COURIER_BREAK_HINT: 'Courier working break',
    COURIER_CARGO_HINT: 'Cargo parameters in arbitrary units. (kg, t, liters, etc.) If you measure the cargo in other units (for example, pallets, bottles, etc.), specify the unit of measure by going to the "Reference unit of measurement"',
    COURIER_PRICE_HINT: 'Calculation of payment to a courier',
    COURIER_CLONE_SELECT: 'Copy Couriers',
    COURIER_CLONE_SELECT_HELP: 'Select the couriers to be copied from the directory into the current route.',
    COURIER_CLONE_LINKED_POINTS: 'Add related points',
    COURIER_CLONE_LINKED_POINTS_HELP: 'If the selected couriers are assigned to any points in the directory, then they will also be copied',
    COURIERS_VEHICLE_TYPE_CAR: 'Passenger car',
    COURIERS_VEHICLE_TYPE_HGV: 'Truck',
    COURIERS_VEHICLE_TYPE_FOOT: 'Walking courier',
    POINTS_TAB: 'Current route addresses',
    POINTS_TAB_HINT: 'Addresses',
    POINTS_ADD_BUTTON: 'Address',
    POINTS_ADD_BUTTON_HINT: 'Add a route point, operating mode and cargo parameters of a route point',
    POINTS_ADD_BASE_BUTTON: 'Warehouse / Garage',
    POINTS_ADD_BASE_BUTTON_HINT: 'Add a warehouse / garage, set its address and mode of operation',
    POINTS_INFO_TITLE: 'Point data',
    POINTS_INFO_WAYBILLS: 'Route sheet points',
    POINTS_INFO_WAYBILLS_MISSED: 'Points with errors and warnings',
    POINTS_EDIT_TITLE: 'Edit a route point',
    POINTS_CREATE_TITLE: 'Create a route point',
    POINTS_EDIT_BASE_TITLE: 'Edit a warehouse / garage',
    POINTS_CREATE_BASE_TITLE: 'Create a warehouse / garage',
    POINTS_LIST_TITLE: 'Address List',
    POINTS_DELETE_CONFIRM: 'Confirm point deletion',
    POINTS_NAME: 'Roue point name',
    POINTS_NAME_HINT: 'Give a name to the route point ("Shopping Center «City»", "Office №56") ',
    POINTS_NAME_BASE: 'Name of the warehouse / garage',
    POINTS_NAME_BASE_HINT: 'Enter the name of the object ("Garage No. 1", "Main warehouse")',
    POINTS_ADDRESS: 'Address',
    POINTS_ADDRESS_HELP: `The address is filled in the following sequence:<br/><br/>
        City, street, house / building. Example: "Moscow, Lenin St., 72" or "Moscow, Lenin, 72"<br/><br/>
        Line with the address should not contain: phone number, mode of operation, comments on payment or acceptance of goods, other additions not related to the address.<br/><br/>
        An example of an address that is not defined in the system: "Moscow, Lenin, 72, entrance from the yard, arrive after 18 hours."<br/><br/>
        After entering the address you will be offered a list of recognized addresses. Select one of them to automatically determine the coordinates.
      `,
    POINTS_COORDS: 'Coordinates',
    POINTS_COORDS_LAT: 'Latitude',
    POINTS_COORDS_LON: 'Longitude',
    POINTS_COORDS_HELP: `When filling in the correct address, the coordinates are filled in automatically. If the coordinates are not determined:<br/><br/>
      1. Enter them manually, filling in both fields: "Latitude" and "Longitude"<br/><br/>
      2. Check the location of the point on the map and, if necessary, move the point to the desired address, the coordinates will be updated automatically`,
    POINTS_WEIGHT: 'Mass of the delivering goods',
    POINTS_VOLUME: 'Volume of delivering goods',
    POINTS_DIRECT: 'Delivery',
    POINTS_BACKHAUL: 'Return',
    POINTS_BACKHAUL_WEIGHT: 'Mass of returning goods to warehouse',
    POINTS_BACKHAUL_VOLUME: 'Volume of returning goods to warehouse',
    POINTS_SHIPPING_TIME: 'Time of service',
    POINTS_SHIPPING_TIME_HELP: 'Average loading / unloading time at a given point on the route',
    POINTS_SHIPPING_BASE_TIME: 'Loading / unloading time',
    POINTS_SHIPPING_BASE_TIME_HELP: 'Indicate approximate loading / unloading time',
    POINTS_PRIORITY: 'Service Priority',
    POINTS_PRIORITY_HINT: 'Add service priority. The larger the number, the higher the priority in order of the route at this point. The priority range is from 1 to the maximum number of route points.',
    POINTS_COMMENT: 'Comment',
    POINTS_COMMENT_HINT: 'Additional information for the route at this point (for example: office / apartment number, company name)',
    POINTS_GARAGE: 'Garage',
    POINTS_DEPOT: 'Warehouse',
    POINTS_GARAGE_DEPOT_HINT: `Select an object to create:<br/><br/>
        <b>Garage</b> – vehicle parking place<br/><br/>
        <b>Warehouse</b> – place of loading / unloading.<br/><br/>
        If you have one object that performs the functions of a garage and a warehouse, mark both points.`,
    POINTS_COURIER: 'Service Courier',
    POINTS_CONTACT_PERSON: 'Contact person',
    POINTS_CONTACT_PERSON_HELP: 'Name of contact person who`s in charge of loading / unloading ',
    POINTS_PHONE: 'Phone',
    POINTS_CONTACT_HINT: 'Contact information of the person who`s in charge of loading / unloading',
    POINTS_COURIER_ID: 'Assign a courier',
    POINTS_COURIER_HELP: `When appointing a courier, he carries out a route at a given point only once during the execution of one route.<br/><br/>
        This field can be left blank, in this case the courier will be assigned automatically when building the route.<br/><br/>
        When resetting route details (number of points and couriers, time and mileage), the courier data will not be saved in the route`,
    POINTS_ASSIGNED_COURIER_ID: 'Pin a courier',
    POINTS_ASSIGNED_COURIER_DELIVERY_HELP: `When pinning a courier, he is automatically assigned to perform the route at this point.<br/><br/>
        When resetting route details (number of points and couriers, time and mileage), a courier will remain assigned to this point`,
    POINTS_ASSIGNED_COURIER_HELP: 'Selected courier begins (and ends) the route in this warehouse / garage',
    POINTS_ASSIGNED_COURIER_LIST: 'List of pinned couriers',
    POINTS_ASSIGNED_COURIER_LIST_HELP: 'This field shows the list of couriers divided by the garage. Its change does not affect anything, however, you can choose a courier to go to his card.',
    POINTS_DETAILS_TITLE: 'Information on selected points',
    POINTS_TOTAL_WEIGHT: 'Total delivery weight',
    POINTS_TOTAL_VOLUME: 'Total delivery volume',
    POINTS_TOTAL_BACKHAUL_WEIGHT: 'Total return weight ',
    POINTS_TOTAL_BACKHAUL_VOLUME: 'Total return volume ',
    POINTS_SUM: 'Sum',
    POINTS_SUM_HINT: 'You can specify in this field the value of the amount that does not affect the calculation of the route.',
    POINTS_COUNT: 'Number of selected points:',
    POINTS_MASS_EDIT: 'Change all selected points',
    POINTS_MASS_DELETE: 'Delete selected points',
    POINTS_CARGO_HELP: 'Cargo parameters for delivery to a given point in arbitrary units (kg, t, liters, etc.). If you do not use in the calculations, then leave "0". If you measure the load in other units (for example, pallets, bottles, etc.), specify the unit of measure by going to the<a to="/catalog/measures"> Unit Reference</a>',
    POINTS_BACKHAUL_CARGO_HELP: 'Cargo parameters for returning from a given point. Work only in the task of distribution. If it is necessary to pick up cargo from this point, then specify the appropriate values. In the task with directions, the return freight goes to the warehouse, even if delivery goes from another point.',
    POINTS_CLONE_SELECT: 'Copy Addresses',
    POINTS_CLONE_SELECT_HELP: 'Select the addresses to be copied from the directory to the current route.',
    POINTS_CLONE_LINKED_COURIERS: 'Add related couriers',
    POINTS_CLONE_LINKED_COURIERS_HELP: 'If any couriers from the directory are assigned to selected points, then they will also be copied',
    POINTS_CLONE_LINKED_ZONES: 'Add related areas',
    POINTS_CLONE_LINKED_ZONES_HELP: 'If the selected points are assigned to any areas from the directory, they will also be copied',
    POINTS_TRACK_LINK: 'Tracking Link',
    SCHEDULE_WORK_START: 'Start of work',
    SCHEDULE_WORK_END: 'End of work',
    SCHEDULE_BREAK_START: 'Start of break',
    SCHEDULE_BREAK_END: 'End of break',
    POINTS_DELIVERY_SCHEDULE_HELP: 'Mode of operation in route point',
    POINTS_DELIVERY_BREAK_HELP: 'Break in working time of a route point',
    POINTS_BASE_SCHEDULE_HELP: 'Warehouse / garage mode of operation',
    POINTS_BASE_BREAK_HELP: 'Break in working hours of the warehouse / garage',
    POINTS_PICKUP: 'Intermediate warehouse',
    POINTS_PICKUP_HELP: 'Choose if there is cargo at this point for delivery to other points',
    POINTS_FROM: 'Source of cargo',
    POINTS_FROM_HELP: 'Select the point where you have to deliver the goods from to the current point',
    POINTS_TEMPLATE_FILE_NAME: 'Addresses import Zig-Zag.xlsx',
    ZONES_TAB: 'Районы',
    ZONES_TAB_HINT: 'Districts',
    ZONES_LIST_TITLE: 'District list',
    ZONES_INFO_TITLE: 'District data',
    ZONES_ADD: 'Add a new district',
    ZONES_ADD_BUTTON: 'Add a district',
    ZONES_GEOMETRY: 'Draw a district',
    ZONES_GEOMETRY_HELP: 'Division of the territory into service areas. Click the DRAW button and draw a polygon on the map.',
    ZONES_COURIERS: 'Attach couriers',
    ZONES_COURIERS_HELP: 'Attaching a courier to the service area. For example, a courier can be attached if he is well-versed in a given territory, lives in or near this area, carried out a route in this area and is already familiar with customers',
    ZONES_POINTS: 'Bind the addresses',
    ZONES_POINTS_HELP: 'When importing addresses, waypoints are automatically snapped to the area according to the geolocation. If addresses are affixed without areas, then they can be combined into areas according to the necessary criteria (for example: priority area, mode of operation only on weekdays)',
    ZONES_SET_GEOMETRY: 'Draw a district',
    ZONES_SELECT: 'Select a district',
    ZONES_CREATE_TITLE: 'Create a new district',
    ZONES_EDIT_TITLE: 'Edit a district',
    ZONES_NAME: 'District name',
    ZONES_NAME_HINT: 'Enter the name of the district to put it into the system and display on the route map.',
    ZONES_DELETE_CONFIRM: 'Confirm district removal',
    ZONES_CLONE_SELECT: 'Copyable districts',
    ZONES_CLONE_SELECT_HELP: 'Select the zones to be copied from the directory to the current route.',
    GROUPS_TAB: 'Groups',
    GROUPS_TAB_HINT: 'Groups',
    GROUPS_LIST_TITLE: 'Group list',
    GROUPS_INFO_TITLE: 'Group data',
    GROUPS_ADD: 'Add a new group',
    GROUPS_ADD_BUTTON: 'Add a group',
    GROUPS_NAME: 'Group name',
    GROUPS_SECTION: 'Item type',
    GROUPS_SECTION_HELP: 'Select the type of items to be grouped.',
    GROUPS_ENTITIES: 'Items',
    GROUPS_ENTITIES_HELP: 'Select the items that belong to this group.',
    GROUPS_SELECT: 'Select a group',
    GROUPS_CREATE_TITLE: 'Create a new group',
    GROUPS_EDIT_TITLE: 'Edit a group',
    GROUPS_DELETE_CONFIRM: 'Confirm group removal',
    GROUPS_CLONE_SELECT: 'Copyable groups',
    GROUPS_CLONE_SELECT_HELP: 'Select the groups to be copied from the directory into the current route.',
    USERS_TAB: 'Users',
    USERS_TAB_HINT: 'Users',
    USERS_LIST_TITLE: 'User list',
    USERS_INFO_TITLE: 'User data',
    USERS_ADD: 'Add a new user',
    USERS_ADD_BUTTON: 'Add a user',
    USERS_ADD_BUTTON_HINT: 'Fill in the user data to provide access rights to the service and mobile application',
    USERS_SELECT: 'Select a user',
    USERS_CREATE_TITLE: 'Create a new user',
    USERS_EDIT_TITLE: 'Edit a user',
    USERS_NAME: 'User name',
    USERS_NAME_HELP: 'Enter the name of the employee (full name)',
    USERS_EMAIL: 'E-mail',
    USERS_EMAIL_HELP: 'Enter the email address of the employee to access the service. For example, your@email.com. You can leave the field empty if you create drivers, or the user does not have a special role.',
    USERS_PASSWORD: 'Password',
    USERS_PASSWORD_HELP: 'Create a password to log in to your Zig-Zag service. Password must be at least 5 characters, must contain latin letters and numbers.',
    USERS_PHONE: 'User phone',
    USERS_PHONE_HELP: `Fill in the phone number for authorization and operation of the courier in the Zig-Zag mobile application.<br/><br/>
        Number should consist only of numbers, without additional characters and be no shorter than 5 characters.<br/><br/>
        Example: <b>89201002020</b>`,
    USERS_DRIVER: 'Courier',
    USERS_DRIVER_HELP: 'If the employee is a courier, click on this item. Next, you can fill out the courier`s work schedule on the calendar',
    USERS_ROLE: 'User role',
    USERS_ROLE_HELP: `Roles of employees to provide access rights to the service within the company:<br/><br/>
        <b>Administrator</b> - has full access to all features of the service, including the appointment of new employees to roles<br/><br/>
        <b>Manager</b> - has the ability to edit directories, build and delete routes<br/><br/>
        <b>Executor</b> - viewing directories and routes, without the possibility of editing them<br/><br/>`,
    USERS_SCHEDULE: 'Schedule',
    USERS_SCHEDULE_HINT: 'Mark the courier`s working days in the calendar. Selected business days will be taken into account when building the route',
    USERS_DELETE_CONFIRM: 'Confirm user deletion',
    FLAGS: 'Conformity flags',
    FLAGS_HELP: 'Select the conformity flags for couriers and points. The courier will go to the address only if the selected courier flag is also selected at the point.<br/><br/>You can contact support to set up a unique list of flags at will.',
    MEASURES_TAB: 'Unit of measure',
    MEASURES_TAB_HINT: 'Unit of measure',
    MEASURES_LIST_TITLE: 'Unit of measure list',
    MEASURES_INFO_TITLE: 'Unit of measure data',
    MEASURES_ADD: 'Add a new unit of measure',
    MEASURES_ADD_BUTTON: 'Add a unit of measure',
    MEASURES_ADD_BUTTON_HELP: 'Adding a new unit of load for route points (e.g. pallets, bottles, etc.). If you need standard units of measure(kg, t, liters, etc.), then they are used by default, this function is not needed.',
    MEASURES_SELECT: 'Select for all addresses',
    MEASURES_SELECT_HELP: 'Select a common unit of measure for all waypoints.',
    MEASURES_CREATE_TITLE: 'Create a new unit of measure',
    MEASURES_EDIT_TITLE: 'Edit unit of measure',
    MEASURES_NAME: 'Unit name',
    MEASURES_DESCRIPTION: 'Unit Description',
    MEASURES_WEIGHT_UNITS: 'Equal to weight units',
    MEASURES_VOLUME_UNITS: 'Equal to volume units',
    MEASURES_UNITS_HELP: 'Indicate the number of standard units of weight / volume (integers or with a dot, eg "10", "1.5", "0.2") that the new unit occupies.',
    MEASURES_DELETE_CONFIRM: 'Confirm unit deletion',
    MEASURES_APPLIED: 'Common Unit',
    MEASURES_APPLIED_HINT: `Common unit of measure for all waypoints.
        At any time, you can create and select another unit of measure, as well as use standard units of measure (kg, t, m3, etc.) by selecting '- Not assigned -' and using them when filling in the data<a to="/catalog/points">waypoint</a>.`,
    DETAILS_TAB: 'Route Details',
    DETAILS_TAB_HINT: 'Details',
    DETAILS_STATUS_CALCULATED: 'Calculated',
    DETAILS_STATUS_PROCESSING: 'Calculation in progress',
    DETAILS_STATUS_ERROR: 'Calculation error',
    DETAILS_STATUS_ENQUEUED: 'In line for calculation',
    DETAILS_STATUS_READY: 'Ready for calculation',
    DETAILS_POINTS_COUNT: 'Number of points',
    DETAILS_POINTS_COUNT_HINT: 'Number of route points, including warehouses / garages and loading / unloading addresses',
    DETAILS_COURIERS_COUNT: 'Number of couriers',
    DETAILS_COURIERS_COUNT_HINT: 'Number of couriers performing the route',
    DETAILS_TOTAL_TIME: 'Total time',
    DETAILS_TOTAL_TIME_HINT: 'Total lead time of all couriers',
    DETAILS_TOTAL_DISTANCE: 'Common path',
    DETAILS_TOTAL_DISTANCE_HINT: 'Total route mileage',
    DETAILS_TOTAL_WEIGHT: 'Total weight',
    DETAILS_TOTAL_WEIGHT_HINT: 'Total weight transported in this route',
    DETAILS_TOTAL_COST: 'Route cost',
    DETAILS_TOTAL_COST_HINT: 'Total cost in arbitrary units, taking into account all the price factors of each courier (departure price, price per km., etc.)',
    DETAILS_CALC_TIME: 'Calculation time',
    DETAILS_CALC_TIME_HINT: 'Operating time of calculation module after the last successful start of calculation',
    DETAILS_CLEAR_CALCULATION: 'Reset calculation ',
    DETAILS_CLEAR_CALCULATION_CONFIRM: 'All calculation results, courier tracks and information about completed points will be destroyed irrevocably. Reset route?',
    DETAILS_CALCULATION_ERROR: 'Error in connection. Run the calculation again.',
    OPTIMIZATION_TAB: 'Optimization',
    OPTIMIZATION_TAB_HINT: 'Optimization.',
    OPTIMIZATION_RUN: 'Route calculation',
    OPTIMIZATION_MANUAL: 'Edit',
    OPTIMIZATION_MANUAL_START: 'Start editing the route manually. After clicking, click on the points in accordance with the order of the route. To remove a point from the route, click on it again.',
    OPTIMIZATION_MANUAL_FINISH: 'Finish editing the route',
    OPTIMIZATION_UPDATE: 'Additional calculation of the route',
    OPTIMIZATION_CONFIRM_RESET: 'Calculation with a FULL RESET of the current state of the route',
    OPTIMIZATION_CONFIRM_UPDATE: 'Calculation with SAVING visited and anchored points',
    OPTIMIZATION_CONFIRM_MANUAL: 'Estimation of manually created routes',
    OPTIMIZATION_CONFIRM_STOP: 'Stop the process and restore the last calculated data?',
    OPTIMIZATION_CANCEL: 'Interrupt the calculation',
    OPTIMIZATION_FORM_TITLE: 'Optimization parameters',
    OPTIMIZATION_FORM_COMMON: 'Main parameters',
    OPTIMIZATION_FORM_ADVANCED: 'Advanced parameters',
    OPTIMIZATION_FORM_SUBMIT: 'Save settings',
    OPTIMIZATION_TASKNAME: 'Type of route',
    OPTIMIZATION_CLUSTERIZE: 'Clustering',
    OPTIMIZATION_CLUSTERIZE_HELP: 'If clustering is enabled, then the task is divided into many subtasks according to the number of couriers, and each subtask is considered separately. Points are combined on the map into small groups, each of them is assigned to one of the couriers. Thus, each of the couriers works in his own small, automatically built area.',
    OPTIMIZATION_SPECNAME: 'Minimization parameter',
    OPTIMIZATION_SPECNAME_HELP: `Select the parameter to be minimized.:<br/><br/>
        <b>Time between addresses:</b>Minimizes the time the courier moves between addresses, not taking into account the time from / to the addresses “warehouse”, “garage”<br/><br/>
        <b>Distance between addresses:</b>Minimizes the distance between addresses, not taking into account the distance from / to the addresses “warehouse”, “garage”<br/><br/>
        <b>Route cost:</b> Software reduces the cost of the route given the courier settings in the directory <a to="/catalog/couriers">курьеры</a>:<br/>
        - Departure price of track<br/>
        - Price per hour<br/>
        - Price per km. of track<br/>
        - Price per kg./km. of track<br/>
        - Price per one address<br/><br/>
        <b>Time of the entire route:</b> Software reduces the execution time of the route as a whole, regardless of the mileage traveled. (for example, a route is faster to follow on a bypass road than on traffic jams).
        This parameter is different from the “Time between addresses” because it reduces the total travel time of the route, taking into account the travel time from / to the addresses “warehouse”, “garage” both at the beginning of the route and when reloading / unloading is necessary.<br/><br/>
        <b>Task of directions:</b> A special type of task where couriers simultaneously deliver and collect cargo. Points may contain cargo for distribution.
        `,
    OPTIMIZATION_CLUSTERIZATION: 'Clustering',
    OPTIMIZATION_STRICT_MODE: 'Strict regime',
    OPTIMIZATION_STRICT_MODE_HELP: 'Points with problems in service (late courier, overloading the vehicle ...) will not fall into the route list.',
    OPTIMIZATION_NDAYS: 'Max. number of days in a route',
    OPTIMIZATION_MAXITER: 'Number of calculation iterations',
    OPTIMIZATION_MAXITER_HELP: 'The number of repetitions of route calculation to find the best option. The more iterations, the better the result, but the longer the calculation time.',
    OPTIMIZATION_RUNS: 'Количество запусков расчёта',
    OPTIMIZATION_RUNS_HELP: 'Number of calculation starts with the selected number of iterations / calculations. Increasing the number of starts gives a more reliable result, but increases the calculation time by a multiple.',
    OPTIMIZATION_BYAGENT: 'By courier',
    OPTIMIZATION_BYZONE: 'By districts',
    OPTIMIZATION_BYZONE_HELP: 'This parameter is used to speed up the calculation of a large number of addresses, by dividing into areas and sequentially calculating each area separately. To work with this parameter, you need to create areas on the map. Points that are not in areas when building a route are taken into account last.',
    OPTIMIZATION_NNEIGHBORS: 'Number of viewing neighboring points',
    OPTIMIZATION_IMPROVE_CLUSTERS: 'Improve clusters',
    OPTIMIZATION_BALANCE_BY_WEIGHT: 'Cluster alignment by weight',
    OPTIMIZATION_DONT_MAKE_ROUTES: 'Do not create routes from clusters',
    OPTIMIZATION_TASK: 'Type of route',
    OPTIMIZATION_TASK_HELP: `Select the type of route task:<br/><br/>
      <b>Cargo collection</b> – includes return to the warehouse in case the car is fully loaded and after visiting the last address.<br/><br/>
      <b>Cargo delivery</b> – the courier always starts moving from the warehouse and returns to the warehouse with full unloading on the route.`,
    OPTNOTIFY_INPROCESS: 'The operation is in progress',
    IMIZATION_TASK_OPTION_DISTRIBUTION: 'Cargo delivery',
    OPTIMIZATION_TASK_OPTION_COLLECT: 'Cargo collection',
    OPTIMIZATION_CROW_MATRICES: 'Distances in straight lines',
    OPTIMIZATION_SKIP_ROUTES: 'Do not create route geometry',
    OPTIMIZATION_SPEED_MULTIPLIER: 'Speed factor',
    OPTIMIZATION_SPEED_MULTIPLIER_HELP: 'Artificial slowdown or increase in courier speed, for example, due to traffic jams, weather conditions, etc. The average vehicle speed is multiplied by the speed factor. (e.g. average speed 25 km / h, speed factor 2 - speed 50 km / h, coefficient 0.5 - speed 12.5 km / h).',
    OPTIMIZATION_TRAFFIC_JAMS: 'Take into account traffic jams',
    OPTIMIZATION_TRAFFIC_JAMS_HELP: 'Slowing down the route’s time taking into account possible traffic jams from Yandex.Maps statistics',
    OPTIMIZATION_PRESERVE_COURIERS: 'Save courier details',
    OPTIMIZATION_PRESERVE_COURIERS_HELP: 'After calculating the route, the points will be assigned to couriers. In subsequent route calculations, these points will remain assigned to couriers. It is mainly used for surgical intervention in an existing route, for example, to remove / add addresses within the existing route list. A user can quickly determine the addresses that cannot be visited at the scheduled time by lowering the speed coefficient.',
    OPTIMIZATION_BALANCE_AGENTS: 'Balance by couriers',
    OPTIMIZATION_BALANCE_AGENTS_HELP: 'Software seeks to distribute route points between couriersto align the time of their work. The larger the value, the smaller the difference in working time between couriers.',
    OPTIMIZATION_TRUCKER_DAYS: 'Numbers of days on long routes',
    OPTIMIZATION_TRUCKER_DAYS_HELP: 'Sets the maximum number of days on the road in order to avoid too long routes for all couriers for whom the "Multi-day route" is selected.',
    OPTIMIZATION_BY_GROUP: 'Automatic clustering',
    OPTIMIZATION_BY_GROUP_HELP: 'Optimization for large tasks.<br/><br/>It splits the task into groups of points and adds them to the calculation one at a time, fixing the bindings to couriers from the calculation of previous groups in accordance with the type and flags of optimization.',
    OPTIMIZATION_OPTION_CLUSTERIZE: 'Clustering',
    OPTIMIZATION_OPTION_OPTIMIZE: 'Optimization',
    OPTIMIZATION_OPTION_MAXWEIGHT: 'Take max. weight',
    OPTIMIZATION_OPTION_MINCLUSTER: 'Time between addresses',
    OPTIMIZATION_OPTION_MINDISTCLUSTER: 'Distance between addresses',
    OPTIMIZATION_OPTION_MINTIME: 'Time of the entire route',
    OPTIMIZATION_OPTION_MINCOST: 'Route cost',
    OPTIMIZATION_OPTION_DIRECTIONS: 'Task of directions',
    OPTIMIZATION_OPTION_AUTO: 'Automatically',
    OPTIMIZATION_OPTION_MANUAL: 'Manual mode',
    OPTIMIZATION_OPTION_NONE: 'By default',
    CUSTOM_FORM_TAB: 'Customer integration',
    SUBMIT_DEFAULT: 'Done',
    SUBMIT_SAVE: 'Save',
    SUBMIT_CREATE: 'Create',
    SUBMIT_SEND: 'Send',
    SUBMIT_CANCEL: 'Cancel',
    SUBMIT_DOWNLOAD: 'Download',
    SUBMIT_EXECUTE: 'Execute',
    FILTERS_TITLE: 'Filter by name',
    FILTERS_DATE: 'Filter by date',
    ITEM_DELETE: 'Delete',
    ITEM_EDIT: 'Edit',
    ITEM_CLONE: 'Clone',
    ITEM_POWER_OFF: 'Turn off',
    ITEM_POWER_ON: 'Turn on',
    ITEM_EDIT_WAYBILL: 'Edit the route',
    ITEM_GROUP_IMPORT: 'Import to the group',
    FIELD_NO_OPTIONS: 'No options to select',
    FIELD_NO_DATA: 'No data',
    FIELD_NO_ITEM: 'Not selected',
    FIELD_SELECTED_COUNT: 'Selected',
    FIELD_BEGIN_DRAW: 'Draw',
    FIELD_STOP_DRAW: 'Finish',
    FIELD_START_SELECT: 'Select',
    FIELD_START_CALENDAR: 'Calendar',
    FIELD_SELECT_ITEMS: 'Select items',
    FIELD_SELECT_FLAGS: 'Select the flags',
    FIELD_SELECT_DAYS: 'Select dates',
    FIELD_NULL_VALUE: '- Not assigned -',
    FIELD_UNDEFINED_VALUE: '- Don`t change -',
    FIELD_HELP: 'Help',
    FIELD_CUSTOM_ACTION: 'Execute',
    FIELD_EXTRA_FIELDS: 'Additional fields',
    WAYBILLS_FIELD_COURIER: 'Courier Name',
    WAYBILLS_FIELD_ADDRESS: 'Point Address',
    WAYBILLS_FIELD_CONTACT_PERSON: 'Contact person',
    WAYBILLS_FIELD_PHONE: 'Phone',
    WAYBILLS_FIELD_POINT_COMMENT: 'Comment on point',
    WAYBILLS_FIELD_DRIVER_COMMENT: 'Driver comment',
    WAYBILLS_FIELD_WORK_START: 'Start of work',
    WAYBILLS_FIELD_WORK_END: 'End of work',
    WAYBILLS_FIELD_BREAK_START: 'Start of break',
    WAYBILLS_FIELD_BREAK_END: 'End of break',
    WAYBILLS_ARRIVAL: 'Estimated arrival',
    WAYBILLS_DEPARTURE: 'Estimated departure',
    WAYBILLS_WEIGHT: 'Customer cargo weight',
    WAYBILLS_WEIGHT_LOADED: 'Cargo weight in car body',
    WAYBILLS_VOLUME: 'Customer cargo volume',
    WAYBILLS_VOLUME_LOADED: 'Cargo volume in car body',
    WAYBILLS_TOTAL_DISTANCE: 'Total path (km.)',
    WAYBILLS_TOTAL_TIME: 'Total time',
    WAYBILLS_TOTAL_COST: 'Total cost',
    WAYBILLS_COMING_TIME: 'Time of status change',
    WAYBILLS_STATUS: 'Status',
    WAYBILLS_STATUS_CODE_1: 'In progress',
    WAYBILLS_STATUS_CODE_2: 'Completed',
    WAYBILLS_STATUS_CODE_5: 'Refuse',
    WAYBILLS_SUM: 'Sum',
    WAYBILLS_PHOTO: 'Control photo',
    WAYBILLS_ORDER: 'Sequence',
    WAYBILLS_ROUTE_CODE: 'Status code ',
    WAYBILLS_ROUTE_CODE_ST: 'Start of execution',
    WAYBILLS_ROUTE_CODE_UL: 'Loading / Unloading',
    WAYBILLS_ROUTE_CODE_RL: 'Loading',
    WAYBILLS_ROUTE_CODE_RU: 'Unloading',
    WAYBILLS_ROUTE_CODE_LD: 'Loading for delivery by directions',
    WAYBILLS_ROUTE_CODE_UD: 'Directional unloading',
    WAYBILLS_ROUTE_CODE_RG: 'Movement along the route',
    WAYBILLS_ROUTE_CODE_RT: 'End of the route',
    WAYBILLS_ROUTE_CODE_EW: 'Return to the garage at the end of the working day.',
    WAYBILLS_ROUTE_CODE_BR: 'Break',
    WAYBILLS_ROUTE_CODE_LH: 'Overnight when moving to the point',
    WAYBILLS_ROUTE_CODE_SR: 'Point maintenance',
    WAYBILLS_ROUTE_CODE_ERR: 'Route error',
    WAYBILLS_ROUTE_CODE_T: 'Point unreachable during working hours',
    WAYBILLS_ROUTE_CODE_W: 'Point weight exceeds load capacity',
    WAYBILLS_ROUTE_CODE_S: 'Point does not fit on schedule',
    WAYBILLS_ROUTE_CODE_L: 'A courier does not get into the working hours of the point',
    WAYBILLS_ROUTE_CODE_OL: 'Maintenance of the point will cause overload',
    WAYBILLS_ROUTE_CODE_OLD: 'Goods for point not loaded',
    WAYBILLS_ROUTE_CODE_WZ: 'Point lies outside the courier area',
    WAYBILLS_ROUTE_CODE_WV: 'Unsuitable courier type for service',
    WAYBILLS_ROUTE_CODE_PV: 'Point priority violation',
    WAYBILLS_ROUTE_CODE_NW: 'No way found to the point in road graph',
    WAYBILLS_ROUTE_CODE_NL: 'The last point in the route does not match the one set by the courier',
    WAYBILLS_ROUTE_CODE_ND: 'Warehouse unavailable',
    EDIT_WAYBILLS_TITLE: 'Manual route editing',
    EDIT_WAYBILLS_COURIERS_FREE: 'Free routes',
    EDIT_WAYBILLS_COURIERS_EDITED: 'Changed routes',
    EDIT_WAYBILLS_RESET_CURRENT: 'Reset the current one',
    EDIT_WAYBILLS_RESET_ALL: 'Reset all',
    EDIT_WAYBILLS_SAVE_WAYBILL: 'Save the route',
    EDIT_WAYBILLS_NO_COURIERS_ERROR: 'No courier found for editing.',
    EDIT_WAYBILLS_LINK: 'Edit the routes',
    LOGIN_AUTORIZATION: 'Authorization',
    LOGIN_LOGIN: 'Log in',
    LOGIN_LOGOUT: 'Log off',
    LOGIN_SIGNUP: 'Registration',
    LOGIN_HELLO: 'Hi, ',
    LOGIN_RESTORE_PASSWORD: 'Restore the password',
    USER_NAME: 'Login',
    USER_PHONE: 'Phone',
    USER_PASSWORD: 'Password',
    USER_CONFIRM_PASSWORD: 'Confirm the password',
    USER_EMAIL: 'E-mail',
    MISC_TEMPLATE_WORKSHEET: 'Template',
    MISC_TEMPLATE_SHEET_NAME: 'Import',
    MISC_TEMPLATE_TITLE: 'Import addresses for calculation by zig-zag.org. service',
    MISC_TEMPLATE_WARNING_TITLE: 'Do not delete the lines below, fill in the data starting with an empty line.',
    MISC_TEMPLATE_DESCRIPTION: 'Latitude and longitude are optional fields. The coordinates without them will be determined from the address themselves. If the address is your warehouse/garage, then fill acc. fields with the number "1".',
    MISC_WAYBILLS_MANIFEST: 'Route sheets (*).xlsx',
    MISC_WAYBILLS_COURIER: 'Courier route sheets (*).xlsx',
    MISC_WAYBILLS_SHEET_ALL: 'All couriers (general statistics)',
    MISC_WAYBILLS_SHEET_ALL_DETAILED: 'All couriers (in details)',
    MISC_LIST_NO_ITEMS: 'Nothing found',
    MISC_ITEM_CURRENT_MANIFEST: 'Create in current route',
    MISC_ITEM_FROM_CATALOG: 'Add from the directory',
    MISC_SELECT_ALL: 'Choose all',
    MISC_UNSELECT_ALL: 'Remove all ',
    MISC_DROP_DOWN_CONTAINER: 'Hidden content, click to see',
    MISC_DELETE_MULTIPLE: 'Delete multiple items',
    MISC_DELETE_MULTIPLE_CONFIRM: 'Delete selected items?',
    MISC_IMPORT_XLS: 'Import',
    MISC_IMPORT_XLS_HINT: 'Upload the completed template with a list of elements in XLS format. You can download the template for filling by clicking on the adjacent button.',
    MISC_DOWNLOAD_XLS: 'Download XLS Template',
    MISC_DOWNLOAD_XLS_HINT: 'Download the template to populate the list of elements in XLS format and then upload',
    NOTIFY_INPROCESS: 'The operation is in progress',
    NOTIFY_ERROR: 'An error has occurred.',
    NOTIFY_SUCCESS: 'Successfully.',
    NOTIFY_DELETE: 'Delete item.',
    NOTIFY_NO_DATA: 'No data.',
    NOTIFY_IMPORT_POINTS_ERROR_DATA: 'The file is damaged or the template is filled incorrectly.',
    NOTIFY_IMPORT_POINTS_ERROR_EMPTY: 'No items found to import.',
    NOTIFY_IMPORT_POINTS_ERROR_LINE: 'Line: ',
    NOTIFY_IMPORT_POINTS_ERROR_FIELD: 'Field: ',
    NOTIFY_IMPORT_POINTS_ERROR_NO_FIELD: 'Unable to import field:',
    NOTIFY_IMPORT_TIMEOUT: 'We apologize, we have a temporary restriction on importing files due to high load. The following imports are possible:',
    NOTIFY_SUBSCRIPTION_EXPIRES: 'Please make payment for the next period. Access to the system will be suspended',
    ERROR_C001: 'Passwords must match',
    ERROR_C002: 'Server is not responding, please contact the manager',
    ERROR_C003: 'The username or password you entered is incorrect',
    ERROR_C004: 'Ошибка регистрации, проверьте введенные данные',
    ERROR_C005: 'Fill in all the fields',
    ERROR_C006: 'Registration completed successfully. Our manager will contact you shortly to get you acquainted with the capabilities of the service. User is not yet active.',
    ERROR_1000: 'Application server internal error',
    ERROR_1001: 'User not found or invalid password.',
    ERROR_1002: 'Email not found',
    ERROR_1003: 'Password not found',
    ERROR_1004: 'Unknown data format: %format%, required "json" or "xml"',
    ERROR_1005: 'Unknown operation: %operation%',
    ERROR_1006: 'No access rights to current manifest',
    ERROR_1007: 'No permissions on current directory',
    ERROR_1008: 'Invalid or missing parameter: %param_name%',
    ERROR_1009: 'Multiple application server errors',
    ERROR_1010: 'Entity% id% not found',
    ERROR_1011: 'The requested task failed',
    ERROR_1012: 'The route is already being calculated',
    ERROR_1013: 'Prohibited Operation',
    ERROR_1014: 'This Email is already registered',
    ERROR_1015: 'Enter phone number',
    ERROR_1016: 'Report Forming Error',
    ERROR_1017: 'User is blocked, please contact the manager',
    ERROR_1018: 'This phone number is already registered',
    ERROR_2000: 'Internal task queue error',
    ERROR_2001: 'Router does not answer',
    ERROR_2002: 'Router error: %message%',
    ERROR_2003: 'Route does not contain couriers',
    ERROR_2004: 'Route does not contain a point',
    ERROR_3000: 'Calculation module internal error',
    ERROR_3001: 'Unknown specification',
    ERROR_3002: 'Unknown task',
    ERROR_3003: 'Points% point_id% did not hit the cluster',
    ERROR_3004: 'Field %field_name% not filled out (courier: %agent_id%, point: %point_id%)',
    ERROR_3005: 'Unknown zone %zone_id% (курьер: %agent_id%, точка: %point_id%)',
    ERROR_3006: 'Too many zones on the route, 63 maximum',
    ERROR_3007: 'The problem of distribution by zones requires only one zone at a point %point_id%',
    ERROR_3008: 'Number of considered neighboring points must be at least 1',
    ERROR_3009: 'Route does not contain warehouses',
    ERROR_3010: 'No access to license server',
    ERROR_3011: 'License Issues',
    ERROR_3012: 'IP blocked',
    ERROR_3013: 'Exceeding Task Limits',
    ERROR_3014: 'Unknown point (курьер: %agent_id%, точка: %point_id%)',
    ERROR_3015: 'Unknown agent (курьер: %agent_id%, точка: %point_id%)',
    ERROR_CODE_UNKNOWN: 'Unknown error code',
    DAYPICKER_WEEKDAYS_SHORT: [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ],
    DAYPICKER_MONTHS: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    DAYPICKER_MONTHS_PARENTAL: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    DAYPICKER_WEEKDAYS_LONG: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
    DAYPICKER_FIRST_DAY_OF_WEEK: 1,
    DAYPICKER_LABELS: {
      'nextMonth': 'Next month',
      'previousMonth': 'Previous month'
    },
    DEFAULT_FLAGS: [
      'Red',
      'Orange',
      'Yellow',
      'Green',
      'Light blue',
      'Blue',
      'Violet',
      'Silver',
      'White',
      'Pink',
      'Gold',
      'Chestnut',
      'Coffee',
      'Brown',
      'Olive',
      'Marsh',
      'Herbal',
      'Turquoise',
      'Aquamarine',
      'Blush',
      'Purple',
      'Crimson',
      'Scarlet',
      'Cyanogen',
      'Bordeaux',
      'Cherry',
      'Chocolate',
      'Beige',
      'Khaki',
      'Shamua',
      'Gray',
      'Black'
    ]
  }
};



const i18n = {
  formatDate(date) {
    return date.toLocaleDateString();
  },

  formatDecimalHours(hours) {
    const h = Math.floor(hours);
    const m = Math.round(hours % 1 * 60);
    const hStr = h ? `${h} ${this.HOURS_ABBR} ` : '';
    const mStr = `${m} ${this.MINUTES_ABBR}`;
    return `${hStr}${mStr}`;
  },

  formatDecimalHoursAsTime(numHours) {
    const hours = isNaN(numHours) ? 0 : numHours;
    const d = Math.trunc(hours / 24);
    const h = Math.trunc(hours - d * 24);
    const m = Math.round((hours - d * 24 - h) * 60);
    const dt = new Date();
    dt.setHours(h, m, 0);
    const dayStr = d ? `${d} ${this.DAY_ABBR} ` : '';
    const timeStr = dt.toLocaleTimeString(undefined, {hour12:Number(this.hour12), hour: '2-digit', minute:'2-digit'});
    return `${dayStr}${timeStr}`;
  },

  formatWeight(numKilo) {
    return round(numKilo, 2);
  },

  'hour12': localStorage.getItem('hour12') || 0,
  ...strings[localStorage.getItem('lang') || 'us'],
  ...distance[localStorage.getItem('units') || 'us'],
  // ...weight[localStorage.getItem('units') || 'metric']
}

export
function setI18N(lang, units, hour12) {
  Object.assign(i18n, strings[lang], distance[units], weight[units], {'hour12': hour12});
  window.location.reload();
}

export default i18n;
