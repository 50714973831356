import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
// import { store } from 'index';

const b = block('Input');

export default
class CoordsInput extends React.PureComponent {
  static fieldName = 'coords';

  static propTypes = {
    captionLat: PropTypes.string.isRequired,
    captionLon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.number),
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  _onChange = (newValue) => {
    this.props.onChangeValue && this.props.onChangeValue(this.props.name, newValue);
  }

  _onLatChange = (e) => {
    this._onChange([Number(e.target.value), this.props.value && this.props.value[1]]);
  }

  _onLonChange = (e) => {
    this._onChange([this.props.value && this.props.value[0], Number(e.target.value)]);
  }

  render() {
    return [
      <label key={'lat_lable'} htmlFor='lat'>{this.props.captionLat}</label>,
      <label key={'lon_lable'} htmlFor='lon'>{this.props.captionLon}</label>,
      <input key={'lat_input'}
        className={b()}
        id='lat'
        type='number'
        step='0.01'
        name='lat'
        value={(this.props.value && this.props.value[0]) || ''}
        onChange={this._onLatChange}
        disabled={this.props.disabled}
      />,
      <input key={'lon_input'}
        className={b()}
        id='lon'
        type='number'
        step='0.01'
        name='lon'
        value={(this.props.value && this.props.value[1]) || ''}
        onChange={this._onLonChange}
        disabled={this.props.disabled}
      />
    ];
  }
}
