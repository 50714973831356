import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { ApolloConsumer } from 'react-apollo';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import ManifestMiniStatus from './ManifestMiniStatus';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { MiniStatus, OptimizationStatus } from '../enums';
import {ButtonKinds} from '../../common/enums';
import { queries } from '../queries';
import { Button, LoadingIndicator } from '../../common/components';
import { Sections, ApolloFetchPolicy } from '../../common/enums';
import ConfirmButton from '../../common/components/ConfirmButton';
import { reducer as mapReducer } from '../../map/actions';
import { getSectionLinkBody } from '../../../base/utils';
import {reducer as updateStateReducer, actions as updateStateActions} from '../../common/updateStateReducer';

const b = block('ManifestControls');


class ConfirmDialog extends React.PureComponent {

  static propTypes = {
    closeModal: PropTypes.func,
    onConfirm: PropTypes.func,
    isEdited: PropTypes.bool,
    inWork: PropTypes.bool
  };

  _onOverlayClick = (e) => {
    if (e.target.className === b('Overlay'))
      this.props.closeModal();
  }

  _onUpdateSelected = () => {
    this.props.onConfirm("update");
    this.props.closeModal();
  }

  _onResetSelected = () => {
    this.props.onConfirm("reset");
    this.props.closeModal();
  }

  _onManualSelected = () => {
    this.props.onConfirm("manual");
    this.props.closeModal();
  }

  _onCancel = () => {
    this.props.closeModal();
  }

  render() {
    const bb = block('ConfirmDialog');
    return (
      <div className={bb()}>
        <div className={bb('Overlay')} onClick={this._onOverlayClick}>
          <div className={bb('WindowDynamicIndent')} />
          <div className={bb('Window')}>
          <div className={b('Header')}></div>
            <div className={bb('ButtonsVertical')}>
              <Button kind={ButtonKinds.WARNING} clickOnce={true} onClick={this._onUpdateSelected}>{I18N.OPTIMIZATION_CONFIRM_UPDATE}</Button>
              <Button kind={ButtonKinds.DANGER} disabled={this.props.inWork} clickOnce={true} onClick={this._onResetSelected}>{I18N.OPTIMIZATION_CONFIRM_RESET}</Button>
              {this.props.isEdited ? <Button kind={ButtonKinds.DANGER} clickOnce={true} onClick={this._onManualSelected}>{I18N.OPTIMIZATION_CONFIRM_MANUAL}</Button> : null}
              <Button kind={ButtonKinds.UNIMPORTANT} clickOnce={true} onClick={this._onCancel}>{I18N.SUBMIT_CANCEL}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ManifestControls extends React.PureComponent {
  static propTypes = {
    manifestId: PropTypes.number,
    optimizationStatus: PropTypes.string,
    recalculationRequired: PropTypes.bool,
    calculated: PropTypes.bool,
    canRunOptimization: PropTypes.bool,
    missedPointsCount: PropTypes.number,
    warningPointsCount: PropTypes.number,
    name: PropTypes.string,
    hasVisitedPoints: PropTypes.bool,
    manifestReady: PropTypes.bool.isRequired,
    manifestError: PropTypes.bool.isRequired,
    setManifestState: PropTypes.func.isRequired,
    distribution: PropTypes.bool.isRequired,
    operation: PropTypes.string,
    fullRoutes: PropTypes.object,
  };

  componentDidMount() {
    this._acceptNewState(this._mapPropsToApolloData(this.props));
  }

  _mapPropsToApolloData = (props) => _.mapKeys(props, (value, key) => _.snakeCase(key));

  _acceptNewState(manifest) {
    this.props.setManifestState({
      optimizationStatus: manifest['optimization_status'],
      optimizationError: manifest['optimization_error_info'],
      calculated: manifest['calculated'],
      recalculationRequired: manifest['recalculation_required'],
    });
    this.props.clearAssignments();
  }

  _renderMiniStatus(optimizationStatus) {
    if (optimizationStatus) {
      if (optimizationStatus.match(OptimizationStatus.ERROR))
        return <ManifestMiniStatus
          manifestId={this.props.manifestId}
          progressData={optimizationStatus.split(' ')[1]}
          manifestStatus={MiniStatus.ERROR} />;
      if (optimizationStatus.match(OptimizationStatus.ENQUEUED))
        return <ManifestMiniStatus
          manifestId={this.props.manifestId}
          manifestStatus={MiniStatus.ENQUEUED} />;
      if (optimizationStatus.match(OptimizationStatus.WAITING))
        return <ManifestMiniStatus
          manifestId={this.props.manifestId}
          progressData={optimizationStatus.split(' ')[1]}
          manifestStatus={MiniStatus.WAITING} />;
      if (optimizationStatus.match(OptimizationStatus.PREPARING))
        return <ManifestMiniStatus
          manifestId={this.props.manifestId}
          progressData={optimizationStatus.split(' ')[1]}
          manifestStatus={MiniStatus.PREPARING} />;
      if (optimizationStatus.match(OptimizationStatus.PROCESSING))
        return <ManifestMiniStatus
          manifestId={this.props.manifestId}
          progressData={optimizationStatus.split(' ')[1]}
          manifestStatus={MiniStatus.PROCESSING} />;
    }
    else if (this.props.calculated && this.props.missedPointsCount)
      return <ManifestMiniStatus
        manifestId={this.props.manifestId}
        progressData={I18N.MANIFEST_STATUS_MISSED_POINTS}
        manifestStatus={MiniStatus.ERROR} />;
    else if (this.props.calculated && this.props.warningPointsCount)
      return <ManifestMiniStatus
        manifestId={this.props.manifestId}
        progressData={I18N.MANIFEST_STATUS_MISSED_POINTS}
        manifestStatus={MiniStatus.WARNING} />;
    else if (this.props.calculated)
      return <ManifestMiniStatus
        manifestId={this.props.manifestId}
        manifestStatus={MiniStatus.SUCCESS} />;
    return null;
  }

  _stopOptimization = (client) => {
    const p = client.query({
      query: queries.OPTIMIZATION_CANCEL,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        'manifest_id': this.props.manifestId,
      },
    });
    p.then((res) => {
      this._acceptNewState(res.data.result);
    });
    p.catch((res) => {
      toast.error(`${I18N.OPTIMIZATION_CANCEL}. ${I18N.NOTIFY_ERROR}`);
      console.log('stop optimization error');
    });
  }

  _startOptimization = (client, optKind, isEdited = false) => {
    const p = client.query({
      query: queries.OPTIMIZATION_START,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        'manifest_id': this.props.manifestId,
        'update': optKind === "reset" ? false : {points: this.props.courierAssignments},
        'routes': (optKind === "manual" && isEdited) ? this.props.fullRoutes[this.props.manifestId] : null,
        'region': 'us',
      },
    });
    p.then((res) => {
      this._acceptNewState(res.data.result);
    });
    p.catch((res) => {
      toast.error(`${I18N.OPTIMIZATION_RUN}. ${I18N.NOTIFY_ERROR}`);
    });
  }

  _renderRunButton = (client) => {
    if (!this.props.canRunOptimization)
      return <ConfirmButton
        className={b('RunButton')}
        confirmMessage={`${I18N.OPTIMIZATION_CONFIRM_STOP}`}
        icon={icons.MANIFEST_CALC_CANCEL}
        rightHint={I18N.OPTIMIZATION_CANCEL}
        onClick={() => this._stopOptimization(client)}/>;

    const isEdited = this.props.fullRoutes[this.props.manifestId] && this.props.fullRoutes[this.props.manifestId].length;

    return <ConfirmButton
      className={b('RunButton')}
      confirmDialog={ConfirmDialog}
      confirmDialogProps={{isEdited: isEdited, inWork: this.props.hasVisitedPoints}}
      icon={icons.MANIFEST_CALC_RUN}
      onClick={(optKind) => this._startOptimization(client, optKind, isEdited)}/>;
  }

  render() {
    if (!this.props.manifestId || !this.props.manifestReady)
      return <div className={b()}>
        <div className={b('InfoBlock')}>
          <LoadingIndicator/>
        </div>
      </div>;
    return (
      <div className={b()}>
        <div className={b('InfoBlock')}>
          <div className={b('Manifest')}>
            <div className={b('ManifestBar')}>
              <Button
                to={`${getSectionLinkBody(this.props.manifestId)}${Sections.MANIFEST_LIST}`}
                icon={icons.MANIFEST_DROP_DOWN}
                className={b('AddButton')}
                rightHint={I18N.MANIFEST_TITLE}
              />
              <div className={b('ManifestTitle')}>
                <span className={b('Name')}>{this.props.name}</span>
              </div>
            </div>
            <div className={b('ManifestHint')}>{I18N.MANIFEST_TAB}</div>
          </div>
          <hr className='vr'/>
          <div className={b('TabSelect')}>
            <Button
              className={b('SectionButton')}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}`}
              rightHint={I18N.POINTS_TAB}
              icon={icons.MANIFEST_POINTS}>{I18N.POINTS_TAB_HINT}</Button>
            <Button
              className={b('SectionButton')}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}`}
              rightHint={I18N.COURIERS_TAB}
              icon={icons.MANIFEST_COURIERS}>{I18N.COURIERS_TAB_HINT}</Button>
            <Button
              className={b('SectionButton')}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}`}
              rightHint={I18N.ZONES_TAB}
              icon={icons.MANIFEST_ZONES}>{I18N.ZONES_TAB_HINT}</Button>
            <Button
              className={b('SectionButton')}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.DETAILS}`}
              rightHint={I18N.DETAILS_TAB}
              icon={icons.MANIFEST_DETAILS}>{I18N.DETAILS_TAB_HINT}</Button>
            <Button
              className={b('SectionButton')}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.OPTIMIZATION}`}
              rightHint={I18N.OPTIMIZATION_TAB}
              icon={icons.MANIFEST_OPTIMIZATION}>{I18N.OPTIMIZATION_TAB_HINT}</Button>
          </div>
          <div className={b('MiniStatus')}>
            {this._renderMiniStatus(this.props.optimizationStatus)}
          </div>
        </div>
        <ApolloConsumer>
          {(client) => this._renderRunButton(client)}
        </ApolloConsumer>
      </div>
    );
  }
}

export default
connect(
  (state) => ({
    operation: state[mapReducer.name].operation,
    fullRoutes: state[mapReducer.name].fullRoutes,
    courierAssignments: state[updateStateReducer.name].courierAssignments,
  }),
  (dispatch) => ({
    clearAssignments: () => dispatch(updateStateActions.clearAssignments()),
  })
)(ManifestControls);
