import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import { store } from 'index';

import InfoBadge from '../../common/components/InfoBadge';
import ListItem from '../common/ListItem';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { Icon } from '../../common/components';
import Button from '../../common/components/Button';
import { actions as mActions } from '../../manifest/actions';
import { 
  AuxilaryTabKinds, 
  // RouteCode, 
  // ErrorCode, 
  Sections 
} from '../../common/enums';
import { decodeTime, getSectionLinkBody } from '../../../base/utils';
// import Hint from '../../common/components/Hint';


const b = block('ListItem');

const codeIcons = {
  'ST': 'Marker_StartPoint',
  'RT': 'Marker_FinishPoint',
  'RL': 'Marker_Crane',
  'RU': 'Marker_Lading',
  'LD': 'Marker_Crane',
  'UD': 'Marker_Lading',
  'SR': 'Marker_Point',
  'EW': 'Marker_Finish',
  'BR': 'Marker_Break',
  'LH': 'Marker_Sleep',

  'T': 'ItemData_Time',
  'W': 'ItemData_MaxWeight',
  'D': 'ItemSnippet_WaypointError',
  'L': 'ItemData_Schedule',
  'S': 'ItemData_Time',
  'OL': 'ItemData_MaxWeight',
  'OLD': 'Marker_Depot',
  'WZ': 'MapView_Zones',
  'WV': 'ItemSnippet_Truck_Off',
  'PV': 'Item_Edit_Waybill',
  'NW': 'ItemSnippet_WaypointError',
  'NL': 'Marker_FinishPoint',
  'ND': 'Marker_PickupPoint'
};

function getRouteBadges(codes, mod) {
  const ret = [];

  for (const c of codes) {
    const icon = codeIcons[c];
    if (icon)
      ret.push(<div className="WaypointAction">
        <Icon key={c} className={'WaypointActionIcon' + (mod && `_${mod}` || '')} svgName={icon}/>
        <div className="tooltip">{I18N['WAYBILLS_ROUTE_CODE_'+c]}</div>
      </div>);
  }

  return ret;
}


export default
class WaybillPointListItem extends React.PureComponent {

  static propTypes = {
    onDelete: PropTypes.func,
    item: PropTypes.object.isRequired,
    manifestId: PropTypes.number.isRequired,
  }

  _getStatusIcon = (status) => {
    if (status === 1)
      return icons.WAYBILL_POINT_STATUS_PENDING;
    if (status === 2)
      return icons.WAYBILL_POINT_STATUS_OK;
    if (status === 5)
      return icons.WAYBILL_POINT_STATUS_REJECT;
    return null;
  }

  _getStatusModifires = (status) => {
    if (status === 1)
      return { 'Pending': true };
    if (status === 2)
      return { 'Ok': true };
    if (status === 5)
      return { 'Reject': true };
    return null;
  }

  _showWaybillPoints = (name, photos) => store.dispatch(mActions.showPhotos(AuxilaryTabKinds.SHOW_PHOTOS, name, photos));

  render() {
    const courierPoint = this.props.item;

    const actionsBadges = getRouteBadges((courierPoint['route_code'] || '').split(';'));
    const errorBadges = getRouteBadges((courierPoint['error_code'] || '').split(';'), courierPoint['route_code'] === 'ERR' ? 'Error' : 'Warning');

    return <ListItem
      key={courierPoint.id}
      id={courierPoint.id}
      number={courierPoint.courier_id}
      link={courierPoint.linkTo && courierPoint.linkTo}
      onClick={courierPoint.onClick && courierPoint.onClick}
      iconStroke={4}
      // icon={'MapView_Waypoints'}
      position={Number(courierPoint.points_served)}
      title={courierPoint.name}
      details={courierPoint.address}
      extra={[...actionsBadges, errorBadges]}
    >
      {courierPoint.status && <Icon
        className={b('WaybillStatusIcon', this._getStatusModifires(courierPoint.status))}
        svgName={this._getStatusIcon(courierPoint.status)}
      />}
      <div className={b('Controls', { 'AlwaysShow': true })}>
        {courierPoint.photos &&
          <Button
            icon={icons.COMMON_PHOTO}
            leftHint={I18N.WAYBILLS_PHOTO}
            onClick={() => this._showWaybillPoints(courierPoint.name, courierPoint.photos)}
          />
        }
        <Button
          icon={icons.COMMON_EDIT}
          leftHint={I18N.ITEM_EDIT}
          to={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/${courierPoint.point_id}/edit`}
        />
      </div>
      <div className={b('InfoBadges')}>
        <InfoBadge iconName={icons.BADGE_SCHEDULE} value={`
          ${I18N.formatDecimalHoursAsTime(courierPoint.arrival_time)} -
          ${I18N.formatDecimalHoursAsTime(courierPoint.departure_time)}
        `}/>
        <InfoBadge iconName={icons.BADGE_TIME} value={I18N.formatDecimalHours(courierPoint.total_time)}/>
        <InfoBadge iconName={icons.BADGE_CAR} value={I18N.formatDistance(courierPoint.total_distance)}/>
        <InfoBadge iconName={icons.BADGE_WEIGHT} value={I18N.formatWeight(courierPoint.total_weight)}/>
      </div>
      {courierPoint.last_status_change && <div className={b('InfoBadges')}>
        <InfoBadge iconName={icons.BADGE_TIME} value={decodeTime(courierPoint.last_status_change)}/>
        <InfoBadge value={courierPoint.driver_comment}/>
      </div>}
    </ListItem>;
  }
}
